export default class Page {
    constructor(number, folder) {
        this.number = number
        this.folder = folder
    }

    get page() {
        return 'comicses/' + this.folder + '/pages/uk/' + this.number + '.jpg'
    }

    original(lang) {
        if (lang === 'uk') {
            return 'comicses/' + this.folder + '/pages/uk/' + this.number + '.jpg'
        } else {
            return 'comicses/' + this.folder + '/pages/en/' + this.number + '.jpg'
        }
    }

    webtoon(lang) {
        if (lang === 'uk') {
            return 'comicses/' + this.folder + '/webtoon/uk/' + this.number + '.jpg'
        } else {
            return 'comicses/' + this.folder + '/webtoon/en/' + this.number + '.jpg'
        }
    }
    
    get thumbnail() {
        return 'comicses/' + this.folder + '/thumbnails/' + this.number + '.jpg'
    }
}