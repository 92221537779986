import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { COLORS } from '../values/values';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../utils/firebase';

const SocialComponent = (props) => {
    const { t } = useTranslation()
    const item = props.item
    const dark = props.dark == true
    const iconStyle = {
        color: dark ? "black" : "white",
        paddingRight: 3,
        fontSize: 22
    }

    return ( <Box>
        {item.website && <Link sx={iconStyle} href={item.website} target="_blank"
            onClick={() => {
                logEvent(analytics, 'open_social_website', 
                {"item": item.website})
                }}>
            <FontAwesomeIcon icon={["fas", "globe"]} />
            </Link>}
        {item.facebook && <Link sx={iconStyle} href={item.facebook} target="_blank"
            onClick={() => {
                logEvent(analytics, 'open_social_facebook', 
                {"item": item.facebook})
                }}>
            <FontAwesomeIcon icon={["fab", "facebook"]} />
            </Link>}
        {item.instagram && <Link sx={iconStyle} href={item.instagram} target="_blank"
            onClick={() => {
                logEvent(analytics, 'open_social_instagram', 
                {"item": item.instagram})
            }}>
            <FontAwesomeIcon icon={["fab", "instagram"]} />
            </Link>}
        {item.patreon &&
        <Button sx={{color: "white",
                     height: 30,
                     marginTop: -1,
                     background: COLORS.red,
                     "&:hover": {
                        background: COLORS.red,
                    }}} href={item.patreon} target="_blank"
                    onClick={() => {
                        logEvent(analytics, 'open_social_patreon', 
                        {"item": item.patreon})
                    }}>
            <FontAwesomeIcon icon={["fab", "patreon"]}/>
            <Typography sx={{fontSize: 10, paddingLeft: 1}}>{t('patreon')}</Typography>
        </Button>}
    </Box> );
}
 
export default SocialComponent;