import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { logEvent } from "firebase/analytics";
import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import News from "../models/News";
import { analytics, db } from "../utils/firebase";
import { COLORS } from "../values/values";
import NewsSkeleton from "./NewsSkeleton";

const NewsDetails = () => {
    const { t, i18n } = useTranslation()
    let lang = i18n.resolvedLanguage
    const { id } = useParams();
    const [news, setNews] = useState(null)

    const fetchNews = async() => {
        const docRef = doc(db, "news", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const c = new News(docSnap.id, docSnap.data())
            setNews(c)
            logEvent(analytics, 'show_news_post', {"title": c.title.localized('en')})
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        fetchNews()
    }, []);

    function DateBlock() {
        const date = [
            ('0' + news.date.getDate()).slice(-2),
            ('0' + (news.date.getMonth() + 1)).slice(-2),
            news.date.getFullYear()
          ].join('.');
        return <Typography fontSize={12} color={COLORS.yellow}>{date}</Typography>
    }

    function PostBlock() {
        return (<Box>
            <Box>
                <Typography variant="h6" color="white">{news.title.localized(lang)}</Typography>
                <DateBlock/>
            </Box>
            <Box
                component="img"
                sx={{
                    objectFit: "cover",
                    maxWidth: "100%",
                }}
                alt={news.title.localized(lang)}
                src={news.image}
                />
            <div style={{color: "white", fontSize: 14, width: "100%"}} dangerouslySetInnerHTML={{__html: news.content.localized(lang)}}></div>
        </Box>)
    }

    return ( <Container sx={{
        maxWidth: "sm",
        minHeight: "100vh",
        paddingTop: 2
    }}>
        { news ? <PostBlock/> : <NewsSkeleton/>}
    </Container> );
}

export default NewsDetails;