import { ref, listAll } from "firebase/storage";
import React, { useState, useEffect } from 'react';
import { auth, storage } from '../utils/firebase';
import { fetchImageAsync } from "../fetch/imageFetch";
import { Button, ImageList, ImageListItem, Typography } from "@mui/material";
import { updateProfile } from "firebase/auth";
import { Box } from "@mui/system";
import { useTranslation } from 'react-i18next'
import { COLORS } from "../values/values";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AvatarSelect = (props) => {
    const { t } = useTranslation()
    const [avatars, setAvatars] = useState([])
    const [selectedAvatar, setSelectedAvatar] = useState(auth.currentUser.photoURL)

    const saveSelectedAvatar = () => {
        auth.currentUser.photoURL = selectedAvatar
        updateProfile(auth.currentUser, {
            photoURL: selectedAvatar
        }).then(() => {
            props.onClose()
        }).catch((error) => {
            console.log(error)
        });
        
    }

    const fetchAvatars = async() => {
        // Create a reference under which you want to list
        const listRef = ref(storage, 'wallpapers/avatars/thumbnails');

        // Find all the prefixes and items.
        listAll(listRef)
        .then((res) => {
            const avas = []
            res.items.forEach((itemRef) => {
                const image = fetchImageAsync(itemRef._location.path_);
                image.then(function (val) {
                    if (val !== undefined) {
                        avas.push(val)
                        if (avas.length === res.items.length) {
                            setAvatars(avas)
                        }
                    }
                });
            });
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    useEffect(() => {
        fetchAvatars()
    }, []);

    return ( <Box >
            <Box sx={{display: "flex", flexDirection: "row", alignContent: "center", justifyContent: 'space-between', paddingBottom: 2}}>
                <Typography sx={{fontSize: 20, fontWeight: "bold"}}>{t('change_avatar')}:</Typography>
                <Button sx={{ color: "black", minWidth: "44px", fontSize: 18}} onClick={props.onClose}>
                    <FontAwesomeIcon icon={["fas", "xmark"]} />
                </Button>
            </Box>
            <ImageList cols={2} sx={{maxHeight: 400}}>
            {avatars.map( (item, index) => (
                <ImageListItem key={index}>
                    <Button onClick={() => {
                        if (item != null) {
                            setSelectedAvatar(item)
                        }
                    }}>
                        <Box
                        component="img"
                        sx={{
                            objectFit: "cover",
                            height: {xs: "35vw", sm: 150, md: 150, lg: 150, xl: 150},
                            width: {xs: "35vw", sm: 150, md: 150, lg: 150, xl: 150},
                            border: selectedAvatar === item ? 5 : 0,
                            borderColor: "green",
                        }}
                        alt=""
                        src={item}
                        />
                    </Button>
                </ImageListItem>
            ))}
            </ImageList>
            <Box textAlign="right" sx={{paddingTop: 3}}>
            <Button sx={{background: COLORS.blue, 
                         color: "white",
                         '&:hover': {
                            backgroundColor: COLORS.button_hover,
                            color: "white",
                        }}} disabled={selectedAvatar === null} onClick={saveSelectedAvatar}>{t('change')}</Button>
            </Box>
        </Box>);
}
 
export default AvatarSelect;