import TranslatableText from './TranslatableText.js'

export default class Stickers {
  constructor(id, data) {
    this.id = id
    this.folder = data.folder
    this.name = new TranslatableText(data.name)
    this.count = data.count
    this.url = data.url
  }

  get cover() {
    return 'stickers/' + this.folder + '/cover.png'
  }
}