import { Box } from "@mui/system";
import { fetchImageAsync } from "../fetch/imageFetch";
import { useTranslation } from 'react-i18next'
import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import PageLoader from "../tools/PageLoader";
import Loader from "../tools/Loader";
import { Button, Typography } from "@mui/material";
import { t } from "i18next";
import { COLORS } from "../values/values";
import { useSwipeable } from "react-swipeable";
import ComicsLocked from "../BuyComics/ComicsLocked";

const ReaderWebtoon = (props) => {
    const comics = props.comics
    const { i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [isLoadingTop, setIsLoadingTop] = useState(false)
    const [webtoons, setWebtoons] = useState({})
    const [imagesToRender, setImagesToRender] = useState([])
    const scrollRef = useRef()
    const oldContentHeight = useRef(0)
    const previousScrollRef = useRef(0)
    const rangeRef = useRef({})
    const ignoreNextScrollEventRef = useRef(true)
    
    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            props.showSettings()
        },
        onSwipedRight: (eventData) => {
            props.showSettings()
        }
    });

    useEffect(() => {
        ignoreNextScrollEventRef.current = true
        initializeRange()
    }, [props.currentPage, props.isMyComics])

    useEffect(() => {
        if (ignoreNextScrollEventRef.current) {
            scrollToNewPage()
        }
        if (isLoadingTop) {
            let newContentHeight = contentHeight()
            scrollRef.current.scrollTop = scrollRef.current.scrollTop + (newContentHeight - oldContentHeight.current)
            setIsLoadingTop(false)
        }
        if (isLoadingMore) {
            setIsLoadingMore(false)
        }
    }, [imagesToRender])

    function scrollToNewPage() {
        if (scrollRef && scrollRef.current) {
            if (props.currentPage == 0) {
                scrollRef.current.scrollTop = 0.0
            } else {
                if (scrollRef && scrollRef.current) {
                    if (props.currentPage >= totalPages() - 1) {
                        let firstHeight = heightWithRatio(imagesToRender[0].height)
                        let secondHeight = heightWithRatio(imagesToRender[1].height)
                        scrollRef.current.scrollTop = firstHeight + secondHeight
                    } else {
                        let firstHeight = heightWithRatio(imagesToRender[0].height)
                        scrollRef.current.scrollTop = firstHeight + 1.0
                    }
                }
            }
        }
    }

    function initializeRange() {
        let length = totalPages()
        let crange = {start: 0, end: 0}
        if (props.currentPage === 0) {
            crange = {start: 0, end: 2}
        } else if (props.currentPage >= length - 1) {
            crange = {start: length - 3, end: length - 1}
        } else {
            crange = {start: props.currentPage - 1, end: props.currentPage + 1}
        }
        let oldRange = rangeRef.current
        if (crange.start !== oldRange.start || crange.end !== oldRange.end) {
            setImagesToRender([])
            preloadRange(crange)
        } else {
            scrollToNewPage()
        }
    }

    function totalPages() {
        let length = comics.pages.length
        if (!props.isMyComics) {
            length = comics.freePages
        }
        return length
    }

    function preloadRange(crange) {
        let length = totalPages()
        for (let i = crange.start; i <= crange.end; i++) {
            if (i === length - 1 && !props.isMyComics) {
                webtoons[i] = {status: "locked", height: window.innerHeight}
            } else if (webtoons[i] === undefined || webtoons[i].status !== "loaded") {
                webtoons[i] = {status: "loading"}
                let page = comics.pages[i]
                let url = fetchImageAsync(page.webtoon(lang))
                url.then(function (val) {
                    if (val !== undefined) {
                        let img = new Image()
                        img.src = val
                        img.onload = (e) => {
                            webtoons[i] = {status: "loaded", image: val, height: e.target.height}
                            validateImages(crange)
                        }
                        img.onerror = () => {
                            webtoons[i] = {status: "error", height: 200}
                            validateImages(crange)
                        }
                    } else {
                        webtoons[i] = {status: "error", height: 200}
                        validateImages(crange)
                    }
                })
            } else {
                validateImages(crange)
            }
        }
    }

    function validateImages(range) {
        let loaded = true
        for (let i = range.start; i <= range.end; i++) {
            if (webtoons[i] === undefined || webtoons[i].status === "loading") {
                loaded = false
            }
        }
        if (loaded) {
            refillImagesToRender(range.start, range.end)
        }
    }

    function heightWithRatio(height) {
        if (height === 200) {
            return 200
        }
        let width = window.innerWidth > 800 ? 800 : window.innerWidth
        let ratio = 800 / width
        return height / ratio
    }

    const handleScroll = (e) => {
        if (scrollRef) {
            let scroll = scrollRef.current.scrollTop
            // check current page
            var totalHeight = 0
            let range = rangeRef.current
            let currentPage = 0
            for (let i = range.start; i <= range.end; i++) {
                let height = 200
                if (webtoons[i] && webtoons[i].height) {
                    height = heightWithRatio(webtoons[i].height)
                    totalHeight = totalHeight + height
                }
                if (height < window.innerHeight) {
                    scroll += window.innerHeight/2
                }
                if (scroll < totalHeight && scroll >= totalHeight - height) {
                    props.onCounterUpdate(i)
                    currentPage = i
                    break
                }
            }
            if (currentPage === range.end) {
                loadMore()
            }
            if (currentPage === range.start) {
                loadTop()
            }
            if (ignoreNextScrollEventRef.current) {
                ignoreNextScrollEventRef.current = false
            } else {
                if (scroll > previousScrollRef.current) {
                    props.hideSettings(true)
                }
                previousScrollRef.current = scroll
            }
            

        }
    }

    function contentHeight() {
        let range = rangeRef.current
        let height = 0
        for (let i = range.start; i <= range.end; i++) {
            if (webtoons[i]) {
                height = height + heightWithRatio(webtoons[i].height)
            }
        }
        return height
    }

    const loadTop = () => {
        let range = rangeRef.current
        if (!isLoadingTop && range.start !== 0) {
            setIsLoadingTop(true)
            oldContentHeight.current = contentHeight()
            let range = rangeRef.current
            let newStart = range.start - 2 < 0 ? 0 : range.start - 2
            preloadRange({start: newStart, end: range.end})
        }
    }
    
    const loadMore = () => {
        if (!isLoadingMore) {
            setIsLoadingMore(true)
            let length = totalPages()
            let range = rangeRef.current
            let newEnd = range.end + 2 > length - 1 ? length - 1 : range.end + 2
            preloadRange({start: range.start, end: newEnd})
        }
    }

    const onReloadWebtoon = (i) => {
        let crange = rangeRef.current
        webtoons[i] = {status: "loading", height: 200}
        let page = comics.pages[i]
        let url = fetchImageAsync(page.webtoon(lang))
        url.then(function (val) {
            if (val !== undefined) {
                let img = new Image()
                img.src = val
                img.onload = (e) => {
                    webtoons[i] = {status: "loaded", image: val, height: e.target.height}
                    validateImages(crange)
                }
                img.onerror = () => {
                    webtoons[i] = {status: "error", height: 200}
                    validateImages(crange)
                }
            } else {
                webtoons[i] = {status: "error", height: 200}
                validateImages(crange)
            }
        })
    }

    const refillImagesToRender = (start, end) => {
        var imgs = []
        for (let i = start; i <= end; i++) {
            let webtoon = webtoons[i]
            webtoon.index = i
            imgs.push(webtoon)
        }
        rangeRef.current = {start: start, end: end}
        setImagesToRender(imgs)
    }

    return ( <Box textAlign="center" {...handlers} onClick={props.showSettings}>
        { imagesToRender.length > 0 ? 
            <Box sx={{lineHeight: 0, height: "100vh", width: "100vw", overflowY: "scroll"}}
                 ref={scrollRef} onScroll={handleScroll}>
                { isLoadingTop && <Box height={130}><Loader/></Box>}
                <Box>
                    {imagesToRender.map( (webtoon, index) => (
                        <Box key={index}>
                            { webtoon.status === "loaded" && 
                                <Box
                                component="img"
                                sx={{
                                    lineHeight: 0,
                                }}
                                maxWidth={'100vw'}
                                alt={index}
                                src={webtoon.image}
                                /> }
                            { webtoon.status === "error" && 
                                <Box sx={{height: "200px",
                                        paddingTop: 3,
                                        width: "100%",
                                        display: "flex-inline", justifyContent: "center"}}>
                                    <Typography sx={{paddingBottom: 1, color: "white"}}>{t('error_on_loading')}</Typography>
                                    <Button sx={{
                                        background: COLORS.blue,
                                        color: "white"
                                    }} onClick={() => { onReloadWebtoon(webtoon.index)}}>{t('reload')}</Button>
                                </Box> }
                            { webtoon.status === "locked" && 
                                <Box sx={{position: "relative", justifyContent: "center", display: "flex", alignItems: "center"}}>
                                <Box sx={{width: "400px", position: "absolute", zIndex: 1000}}>
                                    <ComicsLocked 
                                    userData={props.userData}
                                    currentUser={props.currentUser}
                                    comics={comics} 
                                    darkMode={true}/>
                                </Box>
                                <Box
                                    component="img"
                                    sx={{
                                        objectFit: "cover",
                                        height: "100vh",
                                        filter: "blur(14px) brightness(50%)"
                                    }}
                                    alt={props.page}
                                    src={comics.thumbnail}/>
                            </Box>}
                        </Box>
                    )) }
                </Box>
                { isLoadingMore && <Box height={130}><Loader/></Box>}
        </Box>
        : <PageLoader/> }
    </Box> );
}
 
export default ReaderWebtoon;