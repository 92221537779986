import { Box, Skeleton, Typography } from '@mui/material';
import WallpaperCollectionCover from '../items/WallpaperCollectionCover';
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { COLORS } from '../values/values';

const WallpapersListing = (props) => {
    const { t } = useTranslation()
    let wallpapers = props.wallpapers
    let location = useLocation()
    let ref = useRef()

    useEffect(()=> {
        if (location.hash && location.hash === "#wallpapers" && ref) {
            ref.current.scrollIntoView({behavior: "smooth"})
        }
    }, [location,])

    return ( 
        <Box ref={ref}>
            <Typography sx={{color: "white", paddingBottom: 1, fontSize: 20}}>{t('wallpapers_header')}</Typography>
            { wallpapers.length > 0 ? <Box sx={{alignItems: 'baseline', display: 'inline-flex', flexWrap: "wrap", justifyContent: "center"}}>
                {wallpapers.map( (collection) => (
                    <Box key={collection.id} sx={{paddingRight: 1.5}}>
                        <WallpaperCollectionCover collection={collection}/>
                    </Box> 
                )) }
            </Box> : <Skeleton variant="rectangular" sx={{
                        height: 200,
                        width: 840,
                        background: COLORS.skeleton
                    }} />}
        </Box>
     );
}
 
export default WallpapersListing;