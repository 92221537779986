import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Copyright from './Copyright';
import logo from '../images/logo.png'
import { auth } from '../utils/firebase';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { COLORS } from '../values/values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signInWithGoogle } from '../utils/firebase';
import GoogleButton from 'react-google-button'
import AcceptingAggreements from './AcceptingAggreements';
import { setRandomAvatar } from '../fetch/userDataFetch';

export default function SignUpForm(props) {
  const { t } = useTranslation()

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const nickname = data.get('nickname');
    
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        auth.currentUser.displayName = nickname
        function checkUser() {
          const user = userCredential.user;
          if (user.emailVerified === false) {
            props.updateMode("verifySend")
          }
        }
        setRandomAvatar(nickname).then(() => {
          checkUser()
        }).catch((error) => {
          checkUser()
        })
        
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(errorMessage)
      });
  };

  const [error, setError] = React.useState(null);
  const goSignIn = () => { props.updateMode("signin") }

  return (
    <Box  width={"80vw"} maxWidth={368}>
      {props.onClose && 
      <Button sx={{ color: "black", minWidth: "44px", fontSize: 18, position: "absolute", right: 20}} onClick={props.onClose}>
          <FontAwesomeIcon icon={["fas", "xmark"]} />
      </Button>
      }
      
      <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
                component="img"
                sx={{
                    objectFit: "cover",
                    height: 50,
                    width: 50,
                    borderRadius: 25
                }}
                alt="ЖЕВР"
                src={logo}
                />
          <Typography sx={{paddingTop: 1, fontWeight: "700"}}>{t('signup')}</Typography>
            <Box sx={{paddingTop: 2}}>
              <GoogleButton
                sx={{width: 300}}
                label={t("register_with_google")}
                onClick={signInWithGoogle}
              />
            </Box>
            <Typography sx={{paddingTop: 1}}>
            {t("or_register_with_mail")}
            </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                  margin="normal"
                  autoComplete="given-name"
                  name="nickname"
                  required
                  fullWidth
                  id="nickname"
                  label={t('nickname')}
                />
            <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t('email')}
                  name="email"
                  autoComplete="email"
                />
            <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t('password')}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
            <Box sx={{paddingTop: 2, paddingBottom: 2, display: "flex", flexDirection: "column", alignItems: 'center'}}>
                <Button
                type="submit"
                variant="contained"
                sx={{minWidth: "240px", bgcolor: COLORS.blue}}
                >
                {t('register')}
                </Button>
                <AcceptingAggreements/>
            </Box>
            
            { error && <p>{error}</p>}
            <Button onClick={goSignIn}>
                <Typography sx={{color: COLORS.blue, fontSize: 12, fontWeight: 600}}>
                  {t('already_have_account')}
                </Typography>
            </Button>
          </Box>
        </Box>
        <Copyright sx={{paddingTop: 2}} />
    </Box>
  );
}