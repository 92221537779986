import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useTranslation } from 'react-i18next'
import TranslatableText from "../models/TranslatableText";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";

const PublicOffer = () => {
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const [offerText, setOfferText] = useState(null)

    const fetchOffer = async() => {
        const docRef = doc(db, "docs", "public_offer");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const offer = new TranslatableText(docSnap.data())
            setOfferText(offer)
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        fetchOffer()
    }, [])

    return ( <Box sx={{minHeight: "100vh"}}>
        <Container maxWidth="md">
            <Typography sx={{fontSize: 20, fontWeight: "bold", paddingTop: 2, color: "white"}}>{t('public_offer')}</Typography>
            { offerText && <Box>
                <Typography>
                    <div style={{width: "100%"}} dangerouslySetInnerHTML={{__html: offerText.localized(lang)}}></div>
                </Typography>
                </Box>}
        </Container>
    </Box> );
}
 
export default PublicOffer;