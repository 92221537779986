import { Box, Button, Skeleton, Typography } from "@mui/material";
import useImageFetch from "../fetch/imageFetch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from "react";
import { useState } from "react";
import { fetchUserData } from "../fetch/userDataFetch";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { COLORS, FONTS_FAMILY } from "../values/values";
import { useCallback } from "react";

const AddedComicsCover = (props) => {
    const { t, i18n } = useTranslation()
    let lang = i18n.resolvedLanguage
    const comics = props.comics
    const image = comics.thumbnail
    const [userData, setUserData] = useState(null)
    
    const navigate = useNavigate();
    const openReaderOnPage = useCallback((page) => {
        const path = "/reader/" + comics.id + "/" + page
        navigate(path, {replace: true}) 
    }, [navigate, comics]);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            const user = await fetchUserData();
            setUserData(user)
        }
        fetchData()
    }, []);

    function ProgressBlock() {
        if (userData.progress != null && userData.progress[comics.id] != null) {
            let progress = userData.progress[comics.id]
            let percent = parseInt(progress / (comics.pages.length / 100))

        return <Box sx={{ flexDirection: 'row', paddingBottom: 0.5}}>
                <FontAwesomeIcon style={{display: "inline-flex", color: "white", fontSize: 12}} icon={["fas", "forward"]} />
                <Typography sx={{display: "inline-flex", color: "white", fontSize: 12, paddingLeft: 1}}>{percent}%</Typography>
            </Box>
        } else {
            return null
        }
        
    }

    function ActionsBlock() {
        let progress = userData.progressForComics(comics.id)
        return <Box sx={{paddingBottom: 1.5}}>
                <Button fullWidth 
                onClick={() => {
                    openReaderOnPage(progress)}}
                sx={{ height: "40px", 
                      bgcolor: COLORS.blue, 
                      color: "white", 
                      '&:hover': {
                        backgroundColor: COLORS.button_hover,
                        color: "white",
                        }}}
                    startIcon={<FontAwesomeIcon style={{display: "inline-flex", color: "white", fontSize: 12, paddingRight: 10}} icon={["fas", "play"]} />}>
                
                    {progress ? t('continue_reading') : t('read')}
                </Button>
            </Box>
    }

    return ( <Box sx={{
                width: 200,
                alignItems: 'baseline',
                paddingBottom: 1,
            }}>
            <Box>
            { image ? 
            <Link to={`/comics/${comics.id}`} style={{ textDecoration: 'none', color: "white"}}>
                <Box
                component="img"
                sx={{
                    objectFit: "cover",
                    height: 300,
                    width: 200
                }}
                alt={comics.name.localized(i18n.resolvedLanguage)}
                src={image}
                /></Link> : <Skeleton variant="rectangular" sx={{
                    height: 300,
                    width: 200,
                    height: { xs: 75, md: 150 },
                    width: { xs: 50, md: 75 },
                    background: COLORS.skeleton
                }} /> }
                <Typography sx={{color: "white", fontFamily: FONTS_FAMILY.header,
                                fontSize: 20}}>{comics.name.localized(lang)}</Typography>
                { userData &&
                <Box sx={{paddingTop: 0.5}}>
                    <ProgressBlock />
                    <ActionsBlock />
                    </Box>
                 }
            </Box>
    </Box> );
}
 
export default AddedComicsCover;