import { useParams } from "react-router-dom";
import { doc, getDoc, collection, query, where, getDocs, documentId  } from "firebase/firestore";
import { analytics, db } from '../utils/firebase';
import React, { useState, useEffect } from 'react';
import useImageFetch from "../fetch/imageFetch";
import Author from "../models/Author";
import House from "../models/House";
import SocialComponent from "./SocialComponent";
import WorkList from "./WorkList";
import Comics from "../models/Comics";
import { Box, Skeleton, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next'
import PageLoader from "../tools/PageLoader";
import { COLORS, FONTS_FAMILY } from "../values/values";
import { Container } from "@mui/system";
import { logEvent } from "firebase/analytics";

const CreatorDetails = () => {
    const { type, id } = useParams();
    const [creator, setCreator] = useState(null);
    const [comicses, setComicses] = useState([]);
    
    const fetchCreator = async() => {
        let ref = type === "author" ? "author" : "houses"
        const docRef = doc(db, ref, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let item = null
            if (type === "author") {
                const c = new Author(docSnap.id, docSnap.data())
                item = c
                setCreator(c)
                logEvent(analytics, 'show_author_details', {"title": item.name.localized('en')})
            } else {
                const c = new House(docSnap.id, docSnap.data())
                item = c
                setCreator(c)
                logEvent(analytics, 'show_house_details', {"title": item.name.localized('en')})
            }
            if (item !== null && item.comicses !== null && item.comicses.length > 0) {
                const q = query(collection(db, "comics"), where(documentId(), "in", item.comicses));
                const a = []
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    let c = new Comics(doc.id, doc.data()) 
                    a.push(c)
                })
                setComicses(a)
            }
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        fetchCreator()
    }, []);

    function Details(props) {
        const creator = props.creator;
        const { image } = useImageFetch(creator.cover);
        const { i18n } = useTranslation()
        let lang = i18n.resolvedLanguage

        return <Box>
                <Box sx={{background: COLORS.blue}}>
                    <Container maxWidth="md">
                        <Box sx={{display: "flex",
                                  justifyContent: "center", 
                                  paddingTop: 4, paddingBottom: 4,
                                  flexDirection: "row",
                                  flexWrap: {xs: "wrap", sm: "no-wrap", md: "no-wrap", lg: "no-wrap", xl: "no-wrap"}}}>
                            <Box sx={{marginRight: 4, width: 150, marginLeft: 4}}>
                                { image ? <Box
                                component="img"
                                sx={{
                                    objectFit: "cover",
                                    height: 150,
                                    width: 150,
                                    borderRadius: 75,
                                }}
                                alt={creator.name.localized(lang)}
                                src={image}
                                /> : <Skeleton variant="rectangular" sx={{
                                    height: 150,
                                    width: 150,
                                    borderRadius: 75,
                                }} /> }
                            </Box>
                            <Box sx={{marginRight: 4, marginLeft: 4}}>
                                <Typography sx={{color: "white", fontFamily: FONTS_FAMILY.header, fontSize: 36}}>{ creator.name.localized(lang) }</Typography>
                                <Typography sx={{color: "white", paddingBottom: 1}}>{ creator.synopsis.localized(lang) }</Typography>
                                <SocialComponent item={creator}/>
                            </Box>
                        </Box>
                    </Container>
                </Box>
                
                <Box sx={{minHeight: "80vh", paddingTop: 4, paddingBottom: 8}}>
                    <Container maxWidth="md">
                        {comicses.length > 0 && 
                            <WorkList comicses={comicses}/>
                        }
                    </Container>
                </Box>
            </Box>
    }

    return ( <Box>
        {creator ? <Details creator={creator}/> : <PageLoader/>}
    </Box> );
}
 
export default CreatorDetails;