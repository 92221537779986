import { useParams } from "react-router-dom";
import { doc, getDoc  } from "firebase/firestore";
import { analytics, auth, db } from '../utils/firebase';
import React, { useState, useEffect } from 'react';
import WallpaperCollection from "../models/WallpaperCollection";
import useImageFetch, { fetchImageAsync } from "../fetch/imageFetch";
import { Button, ImageListItem, Modal, Box, Typography, Skeleton} from "@mui/material";
import Masonry from '@mui/lab/Masonry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { saveAs } from 'file-saver'
import { useTranslation } from "react-i18next";
import PageLoader from "../tools/PageLoader";
import { COLORS } from "../values/values";
import { logEvent } from "firebase/analytics";

const GalleryDetails = (props) => {
    const { i18n, t } = useTranslation()
    const lang = i18n.resolvedLanguage
    const { id } = useParams();
    const innerRef = props.innerRef
    const [collection, setCollection] = useState(null);
    const [showDownloadInfo, setShowDownloadInfo] = useState(false)

    const [original, setOriginal] = useState(null)
    const [item, setItem] = useState(null)
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (showDownloadInfo) {
                if (user) {
                    if (user.emailVerified == true) {
                        setShowDownloadInfo(false)
                    }
                } else {
                    setShowDownloadInfo(false)
                }
            }
        })
    }, [])

    const downloadImage = () => {
        if (item) {
            const image = fetchImageAsync(item.original);
            image.then(function (val) {
                if (val !== undefined) {
                    logEvent(analytics, 'gallery_download', {"title": item.name.localized('en')})
                    if (auth.currentUser && auth.currentUser.emailVerified == true) {
                        saveAs(val, item.url);
                    } else {  
                        if (showDownloadInfo) {
                            innerRef.current.doOpenLogin()
                        } else {
                            setShowDownloadInfo(true)
                        }
                    }
                }
            });
        }
    }

    const iconStyle = {
        fontSize: 22, color: "white"
    }

    const fetchCollection = async() => {
        const docRef = doc(db, "wallpapers", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const c = new WallpaperCollection(docSnap.id, docSnap.data())
            setCollection(c)
            logEvent(analytics, 'show_gallery', {"title": c.name.localized('en')})
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        fetchCollection()
    }, []);

    function ImageBlock(props) {
        const item = props.item
        const { image : thumbnail } = useImageFetch(item.thumbnail);
        
        const handleOpen = () => {
            logEvent(analytics, 'gallery_preview_image', {"title": item.name.localized('en')})
            setOpen(true)
            setOriginal(thumbnail)
            setItem(item)
        }

        return <Box>
            <Box>
                <Button onClick={handleOpen}>
                { thumbnail ? <Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        width: "100%",
                    }}
                    alt={item.name.localized(lang)}
                    src={thumbnail}
                    /> : <Skeleton variant="rectangular" sx={{
                        height: 200,
                        bgcolor: COLORS.skeleton,
                        width: 200,
                    }} /> }
                </Button>
                </Box>
            </Box>
    }

    function Details(props) {
        const collection = props.collection;

        return <Box textAlign="center" sx={{minHeight: "100vh", paddingTop: 4, paddingBottom: 8}}>
            <Typography sx={{color: "white", fontWeight: "bold", fontSize: 30, paddingBottom: 2}}>{collection.name.localized(lang)}</Typography>
            <Masonry columns={{xs: 1, sm: 2, md: 3, lg: 4, xl: 4}} spacing={2}>
                {collection.items.map( (item) => (
                    <ImageListItem key={item.url}>
                        <ImageBlock item={item}/>
                    </ImageListItem>
                )) }
            </Masonry>
        </Box>

    }

    return (  <Box>
        {collection ? <Details collection={collection}/> : <PageLoader/>}
            <Modal
                  open={open}
                  onClose={handleClose}
                  BackdropProps={{
                    style: {
                      backgroundColor: COLORS.modal_dim,
                      boxShadow: 'none',
                    },
                  }}
                >
                    <Box sx={{position: 'absolute',
                           top: '50%',
                           left: '50%',
                           transform: 'translate(-50%, -50%)',
                           }}>
                        <Box sx={{display: "flex", flexDirection: "column"}}>
                            <Box sx={{display: "inline-flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <Box>
                                    <Button sx={{
                                        maxWidth: 160
                                    }} onClick={downloadImage} startIcon={<FontAwesomeIcon style={iconStyle} icon={["fas", "cloud-arrow-down"]} />}> 
                                        <Typography sx={{color: "white", fontSize: 8}}>{showDownloadInfo ? t('should_register_to_download') : t("download_original")}</Typography>
                                    </Button>
                                    { showDownloadInfo && 
                                    <Button sx={{
                                        color: "white",
                                        background: COLORS.blue,
                                        "&:hover": {
                                            background: COLORS.yellow,
                                        }
                                    }} onClick={innerRef.current.doOpenLogin}>
                                        <Typography sx={{fontSize: 8}}>{t('signin')}</Typography>
                                    </Button> }
                                </Box>
                                <Button sx={{maxWidth: "44px",
                                            maxHeight: "44px",
                                            minHeight: 0,
                                            minWidth: 0,
                                            padding: 0}} 
                                            onClick={handleClose} startIcon={<FontAwesomeIcon style={iconStyle} icon={["fas", "xmark"]} />}>
                                </Button>
                            </Box>
                            <Box>
                                { (original && item) ? <Box
                                    component="img"
                                    sx={{
                                        objectFit: "cover",
                                        maxWidth: "80vw",
                                        maxHeight: "80vh",
                                    }}
                                    alt={item.name.localized(lang)}
                                    src={original}
                                    /> : <Skeleton variant="rectangular" sx={{
                                        bgcolor: COLORS.skeleton,
                                        height: 200,
                                        width: 200,
                                    }} />}
                                { item && <Typography sx={{color: "white"}}>"{item.name.localized(lang)}"</Typography> }
                            </Box>
                        </Box>
                    </Box>
                </Modal>
    </Box>);
}
 
export default GalleryDetails;