import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { COLORS } from "../values/values";
import { useTranslation } from 'react-i18next'

const NewReleaseBlock = (props) => {
    const { t } = useTranslation()

    return (
            <Box>
                <Box sx={{bgcolor: COLORS.blue, borderRadius: 1, display: 'inline-flex', paddingRight: 0.5, paddingLeft: 0.5}}>
                    <Typography fontSize={12} color="white">
                        {t('new')}
                    </Typography>
                </Box>
            </Box> );
}
 
export default NewReleaseBlock;