import { LinearProgress } from "@mui/material"
import { Box } from "@mui/system"
                        
const Loader = (props) => {
    const color = props.color ? props.color : 'white'
    return ( <Box sx={{display: "flex", color: color, justifyContent: "center", flexDirection: 'row'}}>
            <Box  sx={{width: "200px", paddingTop: 4}}>
                <LinearProgress color="inherit" />
            </Box>
        </Box>)
}

export default Loader;