import { Button, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { fetchImageAsync } from "../fetch/imageFetch";
import { COLORS } from "../values/values";
import PageThumbmailBlock from "./PageThumbnailBlock";

const ReaderPagination = (props) => {
    let comics = props.comics
    let currentPage = props.currentPage
    let isMyComics = props.isMyComics
    const [thumbnails, setThumbnails] = useState(props.thumbnails)

    const fetchThumbnails = () => {
        var arr = {}
        comics.pages.map((page, index) => {
            const image = fetchImageAsync(page.thumbnail);
            image.then(function (val) {
                if (val !== undefined) {
                    arr[index] = val
                    if (Object.values(arr).length === comics.pages.length) {
                        setThumbnails(Object.values(arr))
                        props.onUpdateThumbnails(Object.values(arr))
                    }
                }
            });
        })
    }

    useEffect(() => {
        if (thumbnails.length === 0) {
            fetchThumbnails()
        }
        if (refs[props.currentPage] && refs[props.currentPage].current) {
            refs[props.currentPage].current.scrollIntoView()
        }
    }, []);
    
    var refs = {}

    const PageBlock = (props) => {
        let page = props.page
        let thumbnail = props.thumbnail
        const paginationRef = useRef()
        refs[page.number] = paginationRef
        let selected = page.number == currentPage
        return <Box
            ref={paginationRef}>
            <Button onClick={() => {
                props.onPageSelect(page.number)
            }}>
                {thumbnail ? <PageThumbmailBlock comicsOpen={isMyComics} page={page} image={thumbnail} comics={comics} selected={selected}/> : 
                <Skeleton variant="rectangular" sx={{
                    height: 120,
                    width: 80,
                }} />}
            </Button>
        </Box>
    }

    const PaginationBlock = React.memo(function PaginationBlock({ }) {
        return <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100px",
            }}
        >
            {comics.pages.map((page, index) => (
                <Box key={index}>
                    <PageBlock 
                    key={index} 
                    thumbnail={thumbnails[index]} 
                    page={page} 
                    onPageSelect={props.onPageSelect} 
                    selected={props.currentPage === page.number}/>
                </Box>
            ))}
        </Box>
    })
    return (<Box
        sx={{
        zIndex: 300,
        background: COLORS.gray,
        position: "fixed",
        top: "50px",
        height: "calc(100% - 50px)",
        left: 0,
        overflowY: "scroll",
        overflowX: "hidden"
    }}>
        {comics && <PaginationBlock comics={comics} onPageSelect={props.onPageSelect} />}
    </Box>)
}

export default ReaderPagination;