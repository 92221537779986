import { Box, Button, Skeleton, Typography } from "@mui/material";
import { COLORS } from "../values/values";
import { useTranslation } from 'react-i18next'
import useImageFetch from "../fetch/imageFetch";
import {  useEffect } from "react";
import { fetchUserData } from "../fetch/userDataFetch";
import { useState } from "react";

const BuySuccessComics = (props) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const comics = props.comics
    const darkMode = props.darkMode
    const image = comics.thumbnail
    const [userData, setUserData] = useState(null)

    useEffect(() => {
        async function fetchData() {
            // You can await here
            const user = await fetchUserData();
            setUserData(user)
        }
        fetchData();
    }, []);
    
    function ContinueReadButton() {
        let progress = 0
        if (userData) {
            progress = userData.progressForComics(comics.id)
        }
        return <Button onClick={props.onReadComics} variant="contained" sx={{
                        width: "100%", color: "white", 
                        marginBottom: 1,
                        fontWeigth: "bold", background: COLORS.blue,
                        '&:hover': {
                            backgroundColor: COLORS.button_hover,
                            color: "white",
                        }}}>
                        {progress > 0 ? <Box>
                            <Typography>
                                {t('continue_reading')}
                            </Typography>
                            <Typography sx={{fontSize: 8}}>
                                {progress} {t('page')}
                            </Typography>
                        </Box> : <Typography>{t('read')}</Typography>}
                </Button>
    }

    return ( <Box>
        { image ? <Box
            component="img"
            sx={{
                objectFit: "cover",
                height: 150,
                width: 100,
            }}
            alt={comics.name.localized(lang)}
            src={image}
            /> : <Skeleton variant="rectangular" sx={{
                height: 150,
                width: 100,
                background: COLORS.skeleton, 
            }} /> }
        <Typography sx={{fontSize: 14, color: darkMode ? "white" : "black"}}>{comics.name.localized(lang)}</Typography>
        <Typography sx={{fontSize: 18, fontWeight: "bold", paddingBottom: 2, color: darkMode ? "white" : "black"}}>{t('purchase_success')}</Typography>
        <ContinueReadButton/>
        <Button onClick={props.onClose}>{t('close')}</Button>
    </Box> );
}
 
export default BuySuccessComics;