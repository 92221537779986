import { Link, useParams } from "react-router-dom";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import Comics from "../models/Comics";
import { analytics, auth, db } from '../utils/firebase';
import React, { useState, useEffect } from 'react';
import useImageFetch, { fetchImageAsync } from "../fetch/imageFetch";
import SocialComponent from "./SocialComponent";
import TagsComponent from "./TagsComponent";
import InfoBlocks from "./InfoBlocks";
import ComicsLanguages from "./ComicsLanguages";
import useAuthorsFetch, { fetchAuthors } from "../fetch/creatorFetch";
import CreatorsList, { CreatorListSkeleton } from "./CreatorsList";
import { Box, Button, Modal, Skeleton, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next'
import { COLORS, FONTS_FAMILY } from "../values/values";
import { Container } from "@mui/system";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../tools/Loader";
import PageLoader from "../tools/PageLoader";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageThumbmailBlock from "../reader/PageThumbnailBlock";
import ReactPlayer from "react-player"
import { modalStyle } from '../utils/customStyles';
import ComicsLocked from "../BuyComics/ComicsLocked";
import { logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet";
import UserData from "../models/UserData";
import { useRef } from "react";
import { GENERAL_CONFIG } from "../utils/generalConfig";
import { checkUserData, createUserData } from "../fetch/userDataFetch";

const ComicsDetails = () => {
    const { id } = useParams();
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const [comics, setComics] = useState(null);
    const [headerImage, setHeaderImage] = useState(null)
    const [pagesCount, setPagesCount] = useState(0);
    const [thumbnails, setThumbnails] = useState({})
    const pageStep = 27
    const [userData, setUserData] = useState(null)
    const [authors, setAuthors] = useState([])
    const [houses, setHouses] = useState([])
    
    const [lockedComics, setLockedComics] = useState(false)
    const closeLockedComics = () => {
        setLockedComics(false)
    }
    const [directBuy, setDirectBuy] = useState(false)

    const navigate = useNavigate();
    const openReaderOnPage = useCallback((page) => {
        const path = "/reader/" + comics.id + "/" + page
        navigate(path, {replace: true}) 
    }, [navigate, comics]);

    const openPage = (page) => {
        if (comics.isPageOpen(page.number) || isComicsOpen()) {
            openReaderOnPage(page.number)
        } else {
            setDirectBuy(false)
            setLockedComics(true)
        }
    }    


    let unsubscribeRef = useRef()

    const subscribeToUserDataChanges = () => {
        if (auth.currentUser != null && auth.currentUser.emailVerified) {
            const docRef = doc(db, "userData", auth.currentUser.uid);
            if (unsubscribeRef.current === undefined) {
                unsubscribeRef.current = onSnapshot(docRef, (doc) => {
                    if (doc.exists()) {
                        const c = new UserData(auth.currentUser.uid, doc.data())
                        checkUserData(c)
                        setUserData(c)
                    } else {
                        createUserData(docRef)
                    }
                });
            }
        }
    }

    const fetchComics = async() => {
        const docRef = doc(db, "comics", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const c = new Comics(docSnap.id, docSnap.data())
            const image = fetchImageAsync(c.coverBig);
            image.then(function (val) {
                if (val !== undefined) {
                    setHeaderImage(val)
                }
            });
            setComics(c)
            logEvent(analytics, 'show_comics_details', {"title": c.name.localized('en')})
            const authors = fetchAuthors(c.authors, "author")
            authors.then(function (list) {
                setAuthors(list)
            })
            const houses = fetchAuthors(c.houses, "houses")
            houses.then(function (list) {
                setHouses(list)
            })
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        fetchMorePages()
    }, [comics])

    useEffect(() => {
        subscribeToUserDataChanges()
        auth.onAuthStateChanged(user => {
            if (user) {
                if (userData === null) {
                    subscribeToUserDataChanges()
                }
            } else {
                setUserData(null)
            }
        })
        return () => {
            if (unsubscribeRef.current) {
                unsubscribeRef.current()
                unsubscribeRef.current = undefined
            }
        }
    }, [comics]);

    useEffect(() => {
        fetchComics()
    }, []);

    useEffect(() => {
        setPagesCount(Object.values(thumbnails).length)
    }, [thumbnails])

    function ActionButtons() {
        let showBuy = true
        let progress = 0
        if (userData && userData.isMyComics(comics.id)) {
            showBuy = false
        }
        if (userData) {
            progress = userData.progressForComics(comics.id)
        }
        let priceStr = ""
        if (showBuy) {
            if (comics.price && comics.price.amount && comics.price.currency) {
                priceStr = comics.price.amount + " " + t(comics.price.currency)
            }
        }
        function ReadButtonInside() {
            return <Box>
                {progress > 0 ? <Box>
                            <Typography>
                                {t('continue_reading')}
                            </Typography>
                            <Typography sx={{fontSize: 8}}>
                                {progress} {t('page')}
                            </Typography>
                        </Box> : <Typography>{t('read')}</Typography>}
            </Box>
        }
        if (GENERAL_CONFIG.enableBuyComics == false) {
            showBuy = false
        }
        if (showBuy) {
            return (<Box sx={{width: "100%", 
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between"}}>
                    <Button onClick={() => {openReaderOnPage(progress)}} variant="contained" 
                        sx={{width: "49%", marginRight: "0%", 
                        color: "white", fontWeigth: "bold", 
                        background: COLORS.blue}}>
                        <ReadButtonInside/>
                    </Button>
                    
                    <Button
                    startIcon={<FontAwesomeIcon style={{fontSize: 14, color: "white"}} icon={["fas", "shopping-cart"]}/>} 
                    variant="contained" sx={{width: "49%", 
                    color: "white", fontWeigth: "bold", 
                    background: COLORS.blue}}
                    onClick={() => {
                        setDirectBuy(true)
                        setLockedComics(true)
                    }}><Box>
                            <Typography>
                                {t('buy') + (priceStr.length > 0 ? " - " + priceStr: "")}
                            </Typography>
                            <Typography sx={{fontSize: 8}}>
                                {t('online_version')}
                            </Typography>
                        </Box>
                    </Button>
            </Box>)
        } else {
            return <Button onClick={() => {openReaderOnPage(progress)}} variant="contained" 
                        sx={{width: "100%", 
                        color: "white", fontWeigth: "bold", 
                        background: COLORS.blue}}>
                        <ReadButtonInside/>
                    </Button>
        }
    }

    function ButtonsBlock() {
        return <Box sx={{paddingBottom: 2}}>
            <Box sx={{display: "flex", flexDirection: "row", paddingBottom: 1}}>
            { comics.pageCount > 0 && <ActionButtons/>}
            </Box>
            <Box>
                { comics.paperVersion && <Button target="_blank" 
                    onClick={ () => {
                        logEvent(analytics, 'buy_paper_version', {"title": comics.name.localized('en')})
                    }}
                    href={comics.paperVersion} variant="contained" sx={{ 
                    width: "100%", color: "white", 
                    fontWeigth: "bold", background: "black",
                    '&:hover': {
                        backgroundColor: COLORS.button_hover,
                        color: "white",
                    }}}>{t('buy_paper_version')}</Button> }
            </Box>
        </Box>
    }

    function isComicsOpen() {
        if (userData) {
            return userData.isMyComics(comics.id)
        } else {
            return false
        }
    }

    function fetchMorePages() {
        if (comics && comics.pages) {
            if (pagesCount < comics.pages.length) {
                var pages = pagesCount + pageStep
                if (pages > comics.pages.length) {
                    pages = comics.pages.length
                }
                var arr = {}
                for (let i = 0; i < pages; i++) {
                    if (thumbnails[i]) {
                        arr[i] = thumbnails[i]
                        if (Object.values(arr).length === pages) {
                            setThumbnails(Object.values(arr))
                        }
                    } else {
                        const image = fetchImageAsync(comics.pages[i].thumbnail);
                        image.then(function (val) {
                            if (val !== undefined) {
                                arr[i] = val
                                if (Object.values(arr).length === pages) {
                                    setThumbnails(Object.values(arr))
                                }
                            }
                        });
                    }
                }
            }
        }
    }

    return ( 
        <Box>
            { comics ? 
             <Box>
                <Helmet>
                    <title>{comics.name.localized(lang)}</title>
                    <meta property="og:title" content={comics.name.localized(lang)}/>
                    <meta property="og:image" content={comics.cover}/>
                    <meta property="og:description" content={comics.synopsis.localized(lang)}/>
                </Helmet>
                <Box>
                    <Box sx={{bgcolor: comics.schemeBackgroundColor, paddingTop: 4}}>
                        <Container maxWidth="md" >
                            <Box sx={{flexDirection: "row", display: "flex"}} flexWrap={{xs: "wrap", sm: "nowrap", md: "nowrap", lg: 'nowrap', xl: 'nowrap'}}>
                                <Box sx={{paddingRight: 2, alignItems: "flex-end", display: "flex", flexDirection: "column"}}>
                                    <InfoBlocks comics={comics}/>
                                    <ComicsLanguages comics={comics}/>
                                </Box>
                                <Box sx={{paddingRight: 2}}>
                                    { headerImage ? 
                                        <Button
                                        sx={{padding: 0,
                                            minWidth: 0,
                                            minHeight: 0,
                                            background: "clear",
                                              "&:hover": {
                                                  background: "clear",
                                              }}}
                                            onClick={() => {
                                                let progress = 0
                                                if (userData) {
                                                    progress = userData.progressForComics(comics.id)
                                                }
                                                openReaderOnPage(progress)
                                            }}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    objectFit: "cover",

                                                    height: {xs: "calc((100vw - 120px)*1.5)", sm: "calc(60vw)", md: "calc(75vw)", lg: "calc(75vw)", xl: "calc(75vw)" },
                                                    maxHeight: {xs: "calc((100vw - 120px)*1.5)", sm: "calc(60vw)", md: 450, lg: 450, xl: 450 },

                                                    width: {xs: "calc(100vw - 120px)", sm: "calc(40vw)",  md: "calc(50vw)", lg: "calc(50vw)", xl: "calc(50vw)" },
                                                    maxWidth: {xs: "calc(100vw - 120px)", sm: "calc(40vw)", md: 300, lg: 300, xl: 300 },
                                                }}
                                                alt={comics.name.localized(lang)}
                                                src={headerImage}
                                                />
                                        </Button> : <Skeleton variant="rectangular" sx={{
                                            height: 450,
                                            width: 300,
                                    }} /> }
                                </Box>
                                <Box sx={{color: "white"}}>
                                    <Typography sx={{fontSize: {xs: 30, sm: 30, md: 64, lg: 64, xl: 64}, color: "white", 
                                                    fontFamily: FONTS_FAMILY.header, 
                                                    textTransform: "uppercase",
                                                    lineHeight: 1,
                                                    paddingBottom: 1}}>{ comics.name.localized(lang) }</Typography>
                                    { comics.tags && <TagsComponent fontSize={{xs: 15, sm: 15, md: 24, lg: 24, xl: 24}} comics={comics}/> }
                                    { comics.note && <Box sx={{marginTop: 0.5, textAlign: "center", bgcolor: "black", borderRadius: 1}}>
                                        <Typography fontSize={{xs: 12, sm: 12,  md: 14, lg: 14, xl: 14}}>{comics.note.localized(lang)}</Typography>
                                    </Box>}
                                    <Typography sx={{fontSize: {xs: 12, sm: 12, md: 14, lg: 14, xl: 14}, paddingTop: 1, paddingBottom: 2}}>{ comics.synopsis.localized(lang) }</Typography>
                                    <Box sx={{paddingBottom: 2}}>
                                        <SocialComponent item={comics}/>
                                    </Box>
                                    <Box>
                                        <ButtonsBlock/>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                    <Box sx={{textAlign: "center", color: "white"}}>
                        <Container maxWidth="md">
                            <Box sx={{ display: "inline-flex", paddingTop: 2, justifyContent: "center", flexWrap: "wrap"}}>
                                { (authors.length > 0 && comics.authors) ?
                                    <CreatorsList creators={authors} roles={comics.authors}/>
                                : <CreatorListSkeleton/>
                                }
                                { (houses.length > 0 && comics.houses) ?
                                    <CreatorsList creators={houses} roles={comics.houses}/>
                                : <CreatorListSkeleton/>
                                }
                            </Box>
                            { comics.history && comics.history.localized(lang) !== "" && <Box sx={{paddingTop: 3, paddingBottom: 3}}>
                                <Typography sx={{fontSize: 14}}><b>{t('history')}:</b> {comics.history.localized(lang)}</Typography>
                            </Box> }
                            
                            { comics.trailer.localized(lang) && 
                                <Box sx={{width: "100%", maxWidth: 640, display: "inline-flex", justifyContent: 'center', paddingBottom: 3}}>
                                    <ReactPlayer width="100%" controls={true}
                                            url={comics.trailer.localized(lang)}
                                        />
                                </Box>
                            }
                            
                            <Box sx={{paddingBottom: 10}}>
                                <InfiniteScroll
                                    dataLength={pagesCount}
                                    next={fetchMorePages}
                                    hasMore={pagesCount < comics.pages.length}
                                    loader={ <Loader/> }> 
                                    <Box sx={{display: "flex", justifyContent: "center", flexDirection: 'row', flexWrap: 'wrap'}}>
                                    {(() => {
                                        const rows = [];
                                        for (let i = 0; i < pagesCount; i++) {
                                            let page = comics.pages[i]
                                            if (page) {
                                                rows.push(<Box key={page.number} sx={{paddingRight: 1}}>
                                                    <Button onClick={() => {openPage(page)}}>
                                                        <PageThumbmailBlock comicsOpen={isComicsOpen()} page={page} comics={comics} image={thumbnails[page.number]}/>
                                                    </Button>
                                                </Box>);
                                                }
                                            }
                                        return rows;
                                    })()}
                                    </Box>
                                </InfiniteScroll>
                                
                                            
                            </Box>
                        </Container>
                    </Box>
                </Box>
                <Modal
                    open={lockedComics}
                    onClose={closeLockedComics}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                <Box sx={modalStyle} backgroundColor={"white"}>
                    <ComicsLocked onClose={closeLockedComics}
                                  comics={comics}
                                  directBuy={directBuy}
                                  currentUser={auth.currentUser}
                                  userData={userData}/>
                </Box>
            </Modal>
            </Box> : <PageLoader/>}
        </Box>
     );
}
 
export default ComicsDetails;