import { Box } from '@mui/material';
import en from '../images/en.png'
import uk from '../images/uk.png'

const ComicsLanguages = (props) => {
    const comics = props.comics

    return (<Box sx={{display: "none", flexDirection: "column"}}>
    { comics.translations && 
        comics.translations.map(element => {
            if (element === "uk") {
                return (<Box 
                    component="img"
                    sx={{
                        objectFit: "cover",
                        height: {xs: 20, sm: 20, md: 30, lg: 30, xl: 30},
                        width: {xs: 20, sm: 20, md: 30, lg: 30, xl: 30},
                        marginBottom: 1
                    }}
                    alt="Українська"
                    src={uk}
                    key="uk"
                    />)
            } else if (element === "en") {
                return (<Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        height: {xs: 20, sm: 20, md: 30, lg: 30, xl: 30},
                        width: {xs: 20, sm: 20, md: 30, lg: 30, xl: 30},
                        marginBottom: 1
                    }}
                    alt="English"
                    src={en}
                    key="en"
                    />
                )
            } else {
                return null
            }})
    }
    </Box>);
        
}
 
export default ComicsLanguages;