import Featured from "./featured/Featured.js";
import React, { useState, useEffect } from 'react';
import { collection, query, getDocs } from "firebase/firestore";
import { db } from './utils/firebase';
import Comics from './models/Comics.js'
import MarketingRemoteConfig from './models/MarketingRemoteConfig.js'
import WallpaperCollection from "./models/WallpaperCollection.js";
import WallpapersListing from "./featured/WallpapersListing.js";
import Stickers from "./models/Stickers.js";
import StickersListing from "./featured/StickersListing.js";
import { Box, Button, Container, Typography } from "@mui/material";
import { fetchAndActivate } from "firebase/remote-config";
import { remoteConfig } from "./utils/firebase";
import { getValue } from "firebase/remote-config";
import landing_image from "./images/landing_image.png"
import { useTranslation } from 'react-i18next'
import { COLORS } from "./values/values.js";
import { AnimatePresence, motion } from "framer-motion"
import { useCookies } from "react-cookie";
import NewsList from "./news/NewsList.js";

const Home = () => {
    const [comicses, setComics] = useState([]);
    const [wallpapers, setWallpapers] = useState([]);
    const [stickers, setStickers] = useState([]);
    const [marketingConfig, setMarketingConfig] = useState(null)
    const [showLanding, setShowLanding] = useState(false)
    const [cookies, setCookie] = useCookies(['showLanding']);
    const { t } = useTranslation()

    const onHideLanding = () => {
      setShowLanding(false)
      setCookie('showLanding', false, { path: '/' });
    }

    const fetchComics = async() => {
        const q = query(collection(db, "comics"));
        const querySnapshot = await getDocs(q);
        let comicses = []
        querySnapshot.forEach((doc) => {
              const c = new Comics(doc.id, doc.data())
              if (marketingConfig != null && marketingConfig.newReleases != null) {
                  c.isNewRelease = marketingConfig.newReleases.includes(c.id)
              }
              if (c.visible) {
                  comicses.push(c)
              }
            });
            if (marketingConfig != null && marketingConfig.comicses != null) {
                let sorted = []
                marketingConfig.comicses.forEach ((c) => {
                    let comics = comicses.find(element => element.id === c)
                    if (comics != null) {
                        sorted.push(comics)
                    }
                })
                if (sorted.length > 0) {
                  setComics(sorted)
                } else {
                  setComics(comicses)
                }
            } else {
              setComics(comicses)
            }
    }

    const fetchWallpapers = async() => {
        const q = query(collection(db, "wallpapers"));
        const querySnapshot = await getDocs(q);
        let wallpapers = []
        querySnapshot.forEach((doc) => {
            const c = new WallpaperCollection(doc.id, doc.data())
            wallpapers.push(c)
            });
        setWallpapers(wallpapers)
    }

    const fetchStickers = async() => {
        const q = query(collection(db, "stickers"));
        const querySnapshot = await getDocs(q);
        let stickers = []
        querySnapshot.forEach((doc) => {
            const c = new Stickers(doc.id, doc.data())
            stickers.push(c)
            });
        setStickers(stickers)
    }

    useEffect(() => {
      fetchComics()
    }, [marketingConfig])

    useEffect(() => {
        let show = cookies['showLanding']
        setShowLanding(show == undefined)
        fetchAndActivate(remoteConfig)
        .then(() => {
          let val = getValue(remoteConfig, "marketing")._value
          var myobj = JSON.parse(val);
          if (val !== null) {
              setMarketingConfig(new MarketingRemoteConfig(myobj))
          } else {
              fetchComics()
          }
        })
        .catch((err) => {
          fetchComics()
        });
        fetchWallpapers()
        fetchStickers()
    }, []);

    return ( 
        <Container maxWidth="md">
           <AnimatePresence>
            {showLanding && 
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <Box>
                        <Button onClick={onHideLanding} 
                                sx={{padding: 0,
                                    minWidth: 0,
                                    minHeight: 0,
                                    background: COLORS.gray,
                                      "&:hover": {
                                          background: COLORS.gray,
                                      }}}>
                            <Box
                              component="img"
                              sx={{
                                  objectFit: "cover",
                                  maxWidth: "md",
                                  width: "100%"
                              }}
                              alt={t('zhevr')}
                              src={landing_image}
                              />
                        </Button>
                 </Box>
                  </motion.div>
                }
                </AnimatePresence>
            { comicses && 
              <Box sx={{paddingTop: 3}}>
                <Featured comicses={comicses}/>
              </Box>
            }
            { wallpapers && 
              <Box sx={{paddingTop: 3}}>
                <WallpapersListing wallpapers={wallpapers}/>
              </Box>
            }
            { stickers && 
              <Box sx={{paddingTop: 3, paddingBottom: 4}}>
                <StickersListing stickers={stickers}/>
              </Box>
            }
            <NewsList/>
        </Container>
     );
}
 
export default Home;