import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { COLORS } from '../values/values';

const OperationError = () => {
    const { t } = useTranslation()
    return ( <Box>
        <Typography sx={{color: COLORS.red}}>{t('something_went_wrong')}</Typography>
    </Box> );
}
 
export default OperationError;