import TranslatableText from './TranslatableText.js'

export default class CollectionItem {
  constructor(data, folder) {
    this.folder = folder
    this.name = new TranslatableText(data.name)
    this.url = data.url
  }

  get thumbnail() {
    return 'wallpapers/' + this.folder + '/thumbnails/' + this.url
  }

  get original() {
    return 'wallpapers/' + this.folder + '/' + this.url
  }
}