import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { logEvent } from "firebase/analytics";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom";
import News from "../models/News";
import { analytics, db } from "../utils/firebase";
import NewsPost from "./NewsPost";
import NewsSkeleton from "./NewsSkeleton";

const NewsList = () => {
    const { t } = useTranslation()
    const [news, setNews] = useState([])
    let location = useLocation()
    let ref = useRef()
    
    useEffect(()=> {
        if (location.hash && location.hash === "#news" && ref) {
            ref.current.scrollIntoView({behavior: "smooth"})
        }
    }, [location,])

    const fetchNews = async() => {
        const q = query(collection(db, "news"), orderBy("date", "desc"));
        const querySnapshot = await getDocs(q); 
        let news = []
        querySnapshot.forEach((doc) => {
            const c = new News(doc.id, doc.data())
            news.push(c)
            });
        logEvent(analytics, 'show_news')
        setNews(news)
    }

    useEffect(() => {
        fetchNews()
    }, [])

    return ( <Container sx={{
        maxWidth: "sm",
        minHeight: "100vh",
        paddingTop: 2
    }}>
        <Typography ref={ref} sx={{color: "white", paddingBottom: 1, fontSize: 20}}>{t('news')}</Typography>
        { news.length > 0 ? <Box>
            {news.map( (post) => (
                    <Box paddingBottom={3} key={post.id}>
                        <NewsPost news={post}/>
                    </Box>
                )) }
        </Box> : <Box>
            <NewsSkeleton/>
            <NewsSkeleton/>
            <NewsSkeleton/>
        </Box>}
    </Container> );
}
 
export default NewsList;