import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import ComicsCover from "../items/ComicsCover";
import { useTranslation } from 'react-i18next'

const WorkList = (props) => {
    const { t } = useTranslation()

    let comicses = props.comicses
    return ( <Box>
            <Box sx={{display: "inline-flex", flexDirection: "row", flexWrap: "wrap"}}>
                {comicses.map( (comics) => (
                    <Box key={comics.id} sx={{paddingRight: 1.5}}>
                        <ComicsCover key={comics.id} comics={comics}/>
                    </Box>
                )) }
            </Box>
        </Box> );
}
 
export default WorkList;