import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { COLORS } from "../values/values";

const PageThumbmailBlock = (props) => {
    let page = props.page
    let comics = props.comics
    let image = props.image
    let selected = props.selected

    function pageIsOpen(page) {
        return comics.isPageOpen(page.number) || props.comicsOpen
    }

    return (<Box sx={{overflow: 'hidden', marginBottom: 1, position: "relative"}}>
               { page.number > 0 && 
                    <Box sx={{
                        backgroundColor: "black", 
                        fontSize: 12, 
                        color: "white", 
                        p: 0.5, 
                        position: "absolute",
                        zIndex: 1000}}>
                        {page.number}
                    </Box> }
                    { !pageIsOpen(page) && 
                        <Box sx={{
                            position: "absolute",
                            color: "white",
                            left: "35px",
                            top: "55px",
                            zIndex: 1000}}>
                            <FontAwesomeIcon icon={["fas", "lock"]} />
                        </Box>
                    }
                    <Box
                        component="img"
                        sx={{
                            objectFit: "cover",
                            height: 120,
                            width: 80,
                            border: selected ? 3 : 0,
                            borderColor: COLORS.blue,
                            filter: `blur(${pageIsOpen(page) ? "0px" : "5px"})`
                        }}
                        alt={page.number}
                        src={image}/>
            </Box>);
}
 
export default PageThumbmailBlock;