import { Button } from "@mui/material";
import donate from '../images/donate.png'

const DonateButton = () => {
    return ( <Button sx={{
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        position: "fixed",
        left: 0,
        top: "51px",
        zIndex: 1001
    }}
    href={"https://send.monobank.ua/jar/3LxvsoUbAb?fbclid=IwAR18diYf8COGj8QGnvYku2RLIFVGNfCEWiEh_hFRQn7NZ772yx5-YvXRo4g"} target="_blank">
        <img src={donate} width={30}/>
    </Button> );
}
 
export default DonateButton;