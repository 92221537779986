export default class UserData {
    constructor(id, data) {
        this.id = id
        this.progress = data.progress
        this.lastReadComics = data.lastReadComics
        this.myComics = data.myComics
        if (data.lastTimeUpdated != null) {
            this.announceDate = new Date(data.lastTimeUpdated.seconds * 1000)
        }
        this.interfaceLanguage = data.interfaceLanguage
        this.comicsLanguage = data.comicsLanguage
    }

    isMyComics(id) {
        if (this.myComics === null) {
            return false
        }
        return this.myComics.includes(id)
    }

    progressForComics(id) {
        if (this.progress && this.progress[id]) {
            return this.progress[id]
        } else {
            return 0
        }
    }
}