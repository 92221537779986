import { MenuItem, Tooltip, IconButton, Avatar, Menu, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import en from '../images/en.png'
import uk from '../images/uk.png'
import { useTranslation } from 'react-i18next'
import { useEffect } from "react";

const SelectLanguage = (props) => {
    const { t, i18n } = useTranslation()

    const [language, setLanguage] = useState(uk);
    const setUkrainian = () => {
        setLanguage(uk);
        i18n.changeLanguage("uk")
        handleCloseLanguageMenu()
    };

    const setEnglish = () => {
        setLanguage(en);
        i18n.changeLanguage("en")
        handleCloseLanguageMenu()
    }

    useEffect(() => {
        let lang = i18n.resolvedLanguage
        if (lang === "uk") {
            setLanguage(uk)
        } else {
            setLanguage(en)
        }
    }, [i18n.resolvedLanguage]);

    const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
    const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElLanguage(event.currentTarget);
      };
    
    const handleCloseLanguageMenu = () => {
        setAnchorElLanguage(null);
    };
    
    return ( 
        <Box>
        <Tooltip title={t('language')}>
            <IconButton onClick={handleOpenLanguageMenu}>
                <Avatar src={language} sx={{ width: 24, height: 24 }}/>
            </IconButton>
        </Tooltip>
        <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElLanguage}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElLanguage)}
            onClose={handleCloseLanguageMenu}
            >

            <MenuItem key="uk" onClick={setUkrainian}>
                <Typography textAlign="center">Українська</Typography>
            </MenuItem>
            <MenuItem key="en" onClick={setEnglish}>
                <Typography textAlign="center">English</Typography>
            </MenuItem>
            </Menu>
        </Box> 
        );
}
 
export default SelectLanguage;