import { Button, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useTranslation } from 'react-i18next';
import landing_image from "../images/landing_image.png";
import TranslatableText from "../models/TranslatableText";
import { useCallback, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { COLORS } from "../values/values";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const [offerText, setOfferText] = useState(null)
    const navigate = useNavigate();
    const openHomeComics = useCallback(() => navigate('/#comics', {replace: true}), [navigate]);
    
    const fetchOffer = async() => {
        const docRef = doc(db, "docs", "about_us");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const offer = new TranslatableText(docSnap.data())
            setOfferText(offer)
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        fetchOffer()
    }, [])

    return ( <Box sx={{minHeight: "100vh"}}>
        <Container maxWidth="md">
            <Typography sx={{fontSize: 20, fontWeight: "bold", paddingTop: 2, color: "white"}}>{t('about_us')}</Typography>
            { offerText && <Box>
                <Typography>
                    <div style={{width: "100%"}} dangerouslySetInnerHTML={{__html: offerText.localized(lang)}}></div>
                </Typography>
                <Box>
                        <Button onClick={ openHomeComics } 
                                sx={{padding: 0,
                                    minWidth: 0,
                                    minHeight: 0,
                                    background: COLORS.gray,
                                      "&:hover": {
                                          background: COLORS.gray,
                                      }}}>
                            <Box
                              component="img"
                              sx={{
                                  objectFit: "cover",
                                  maxWidth: "md",
                                  width: "100%"
                              }}
                              alt={t('zhevr')}
                              src={landing_image}
                              />
                        </Button>
                 </Box>
                </Box>}
        </Container>
    </Box> );
}
 
export default AboutUs;