import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { COLORS } from '../values/values';

const AcceptingAggreements = (props) => {
    const { t } = useTranslation()
    const buyMode = props.buyMode

    return ( <Box sx={{marginTop: 1}}>
        <Typography sx={{fontSize: 10,
                        color: props.darkMode ? "white" : "black",
                        textAlign: "center"}}>
            {(buyMode ? t('buying_you_are_accepting') : t('registering_you_are_accepting')) + " "} 
            <Button onClick={()=>window.open('/offer','_blank')}
                sx={{
                minWidth: 0,
                minHeight: 0,
                padding: 0,
            }}>
                <Typography sx={{
                    fontSize: 10,
                    textTransform: "none",
                    color: COLORS.blue}}>{t('public_offer_accept')}</Typography>
            </Button>
        </Typography>
        
    </Box> );
}
 
export default AcceptingAggreements;