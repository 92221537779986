import { Box, ImageList, ImageListItem, Skeleton, Typography } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { db } from '../utils/firebase';
import { Link, useParams } from "react-router-dom";
import Comics from "../models/Comics";
import { doc, getDoc } from "firebase/firestore";
import useImageFetch from "../fetch/imageFetch";
import { useTranslation } from "react-i18next";

const PagesList = () => {
    const { i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const { id } = useParams();
    const [comics, setComics] = useState(null);

    const fetchComics = async() => {
        const docRef = doc(db, "comics", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const c = new Comics(docSnap.id, docSnap.data())
            setComics(c)
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        fetchComics()
    }, []);

    function PageBlock (props) {
        const page = props.page;
        const { image } = useImageFetch(page.thumbnail)
        return <Box>
               <Link to={`/reader/${comics.id}/${page.number}`} style={{ textDecoration: 'none'}}>
               { page.number > 0 && 
                    <Box sx={{
                        backgroundColor: "black", 
                        fontSize: 12, 
                        color: "white", 
                        display: "inline-flex", 
                        p: 0.5, 
                        position: "absolute",
                        top: 0,
                        borderRadius: 1}}>
                        {page.number}
                    </Box> }
               { image ? <Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        height: 150,
                        width: 100,
                        borderRadius: 1,
                        display: "inline-flex"
                    }}
                    alt={page.number}
                    src={image}
                    /> : <Skeleton variant="rectangular" sx={{
                        height: 150,
                        width: 100,
                        borderRadius: 1,
                        display: "inline"
                    }} /> }
               </Link>
            
            </Box>
    }

    return ( <Box>
                { comics && <Box>
                    <Typography sx={{color: "white", fontSize: 20}}>{ comics.name.localized(lang) }</Typography>
                    <ImageList sx={{padding: 1}} cols={8}>
                    {comics.pages.map((item) => (
                        <ImageListItem key={item.number}>
                            <PageBlock page={item}/>
                        </ImageListItem>
                    ))}
                    </ImageList>
                    </Box> }
                </Box> );
}
 
export default PagesList;