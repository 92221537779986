import UserData from '../models/UserData';
import { auth, db, storage } from '../utils/firebase';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fetchImageAsync } from './imageFetch';
import { updateProfile } from 'firebase/auth';
import { listAll, ref } from 'firebase/storage';

export const setRandomAvatar = async(nickname) => {
    if (auth.currentUser) {
        // Create a reference under which you want to list
        const listRef = ref(storage, 'wallpapers/avatars/thumbnails');
          // Find all the prefixes and items.
        listAll(listRef)
        .then((res) => {
            var randomNumber = Math.floor(Math.random() * (res.items.length - 0 + 1)) + 0
            let itemRef = res.items[randomNumber]
            const image = fetchImageAsync(itemRef._location.path_);
            image.then(function (val) {
                auth.currentUser.photoURL = val
                return updateProfile(auth.currentUser, {
                    photoURL: val,
                    displayName: nickname
                })
            })
        }).catch((error) => {
            return Promise.reject(new Error('error'))
        });
    }
}

export const updateUserDataParam = async(name, data) => {
    if (auth.currentUser != null && auth.currentUser.uid != null) {
        const docRef = doc(db, "userData", auth.currentUser.uid);
        return setDoc(docRef, {[name]: data}, { merge: true })
    }
    return Promise.reject(new Error('no userdata'))
}

export const addUserComics = async(id) => {
    const user = await fetchUserData();
    if (user.myComics !== null && user.myComics.includes(id)) {
        return Promise.resolve(true);
    } else {
        let myComics = user.myComics
        if (myComics === null) {
            myComics = [id]
        } else {
            myComics.push(id)
        }
        return updateUserDataParam("myComics", myComics)
    }
}

export const updateComicsProgress = async(id, page) => {
    if (auth.currentUser != null && auth.currentUser.uid != null) {
        const user = await fetchUserData();
        if (user && user.progress) {
            if (user.progress[id]) {
                if (user.progress[id] != page) {
                    user.progress[id] = page
                    updateUserDataParam("progress", user.progress)
                }
            } else {
                user.progress[id] = page
                updateUserDataParam("progress", user.progress)
            }
        } else {
            updateUserDataParam("progress", {id: page})
        }
    }
}

export const fetchUserData = async() => {
    if (auth.currentUser != null && auth.currentUser.uid != null) {
        const docRef = doc(db, "userData", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const c = new UserData(auth.currentUser.uid, docSnap.data())
            return Promise.resolve(c);
        } else {
            let data = { progress: {},
            lastReadComics: {},
            myComics: [],
            email: auth.currentUser.email,
            lastTimeUpdated: new Date(),
            interfaceLanguage: "uk",
            comicsLanguage: "uk"
            }
            setDoc(docRef, data, { merge: true });
            return Promise.resolve(data);
        }
    } else {
        return Promise.resolve(null)
    }
}

export const checkUserData = (data) => {
    console.log(data.email)
    if (data.email === undefined) {
        updateUserDataParam("email", auth.currentUser.email)
    }
}

export const createUserData = (docRef) => {
    let data = { progress: {},
    lastReadComics: {},
    myComics: [],
    email: auth.currentUser.email,
    lastTimeUpdated: new Date(),
    interfaceLanguage: "uk",
    comicsLanguage: "uk"
    }
    setDoc(docRef, data, { merge: true });
}
