import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SelectLanguage from "../navbar/SelectLanguage";
import ReaderReadMode from "./ReaderReadMode";
import logo_text from '../images/logo_text.png'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from "react";
import ReaderPagination from "./ReaderPagination";
import ComicsLocked from "../BuyComics/ComicsLocked";
import { modalStyle } from "../utils/customStyles";
import { COLORS } from "../values/values";
import { GENERAL_CONFIG } from "../utils/generalConfig";

const ReaderSettings = (props) => {
    const currentPage = props.currentPage
    const onPageSelect = props.onPageSelect
    const mode = props.mode
    const comics = props.comics
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const [showThumbnails, setShowThumbnails] = useState(false)
    const [lockedComics, setLockedComics] = useState(false)
    const [showBuy, setShowBuy] = useState(false)
    const [currentUser, setCurrentUser] = useState(props.currentUser)
    const displayThumbnails = () => {
        if (showThumbnails) {
            setShowThumbnails(false)
        } else {
            setShowThumbnails(true)
        }
    };

    useEffect(() => {
        if (props.userData) {
            if (props.userData.isMyComics(comics.id)) {
                setBuyStatus(false)
            } else {
                setBuyStatus(true)
            }
        } else {
            setBuyStatus(true)
        }
    }, [props.userData])

    useEffect(() => {
        setCurrentUser(props.currentUser)
    }, [props.currentUser])

    const navigate = useNavigate();

    const iconStyle = {
        color: "white",
        width: 40,
        height: 40,
        minHeight: 50,
        maxHeight: 50, 
        minWidth: 40,
        fontSize: 18,
        display: "inline-flex",
    }
    
    function setBuyStatus(show) {
        if (GENERAL_CONFIG.enableBuyComics == false) {
            setShowBuy(false)
        } else {
            setShowBuy(show)
        }
    }

    function HeaderBlock() {
        return <Box
                display="flex"
                justifyContent="end"
                alignItems="flex-end"
            >
            <Box sx={{display: "none", flexDirection: "column", justifyContent: "center", height: "50px"}}>
                <SelectLanguage/>
            </Box>
            <HideButton/>
        </Box>
    }

    function NavigationBlock() {
        return <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{height: "50px"}}
                >
            <Button sx={iconStyle} onClick={props.onPreviousPage}>
                <FontAwesomeIcon icon={["fas", "angle-left"]} />
            </Button>
            <Box sx={{textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "middle"}}>
                <Typography sx={{color: "white", fontSize: 18, fontWeight: "bold"}}>{currentPage > 0 ? currentPage : t('cover')}</Typography>
            </Box>
            <Button sx={iconStyle} onClick={props.onNextPage}>
                <FontAwesomeIcon icon={["fas", "angle-right"]} />
            </Button>
        </Box>
    }

    function CommonSettings() {
        return <Box sx={{display: "inline-flex"}}>
            <Button sx={iconStyle} onClick={props.showTutorial}>
                <FontAwesomeIcon icon={["fas", "circle-question"]} />
            </Button>
            <ReaderReadMode 
                setClassic={props.setClassic} 
                setWebtoon={props.setWebtoon} 
                mode={mode}/>
        </Box>
    }

    function PagesButton() {
        return <Box>
            <Button sx={{height: "50px", color: "white", minWidth: "44px", fontSize: 18}} onClick={displayThumbnails}>
                <FontAwesomeIcon icon={["fas", showThumbnails ? "folder-open" : "folder"]} />
            </Button>
        </Box>
    }

    function CloseButton() {
        return <Box>
            <Button sx={{height: "50px", color: "white", minWidth: "44px", fontSize: 18}} onClick={ () => navigate("/comics/" + comics.id, {replace: true}) }>
                <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </Button>
        </Box>
    }

    function HideButton() {
        return <Box>
            <Button sx={{height: "50px", color: "white", minWidth: "44px", fontSize: 18}} onClick={props.hideSettings}>
                <FontAwesomeIcon icon={["fas", "eye"]} />
            </Button>
        </Box>
    }

    function BuyBlock() {
        return <Box>
            { showBuy ?
                <Button 
                    startIcon={<FontAwesomeIcon style={{fontSize: 14, color: "white"}} icon={["fas", "shopping-cart"]}/>} 
                    variant="contained" sx={{
                    marginTop: "7px",
                    color: "white", fontWeigth: "bold", 
                    background: COLORS.blue}}
                    onClick={() => {
                        setLockedComics(true)
                    }}>
                    <Box>
                            <Typography sx={{fontSize: 10}}>
                                {t('buy')}
                            </Typography>
                            <Typography sx={{fontSize: 8}}>
                                {t('online_version')}
                            </Typography>
                        </Box>
                </Button> :
                <Button sx={{display: "block"}} onClick={() => {navigate("/", {replace: true})}}>
                    <Box
                        component="img"
                        sx={{
                            objectFit: "cover",
                            height: "40px",
                            alignItems: "center"
                        }}
                        alt="ЖЕВР"
                        src={logo_text}
                        />
                </Button>}
        </Box>
    }

    return ( 
        <Box>
            {comics && 
            <Box sx={{
                display: "inline-flex",
                zIndex: 300,
                paddingLeft: 1,
                paddingRight: 1,
                top: 0,
                width: "100vw",
                height: "50px",
                position: "fixed",
                backgroundColor: "black",
            }}>
                <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100vw"}}>
                    <Box sx={{flexDirection: "row", display: "inline-flex"}}>
                        <CloseButton/>
                        <PagesButton/>
                    </Box>
                    <Box>
                        <NavigationBlock/>
                    </Box>
                    <Box>
                        <HeaderBlock/>
                    </Box>
                </Box>
                { showThumbnails &&
                    <ReaderPagination currentPage={currentPage}
                                      thumbnails={props.thumbnails}
                                      onUpdateThumbnails={props.onUpdateThumbnails}
                                      onPageSelect={onPageSelect}
                                      comics={comics}
                                      isMyComics={props.isMyComics}/>}
            </Box>}
            { comics && 
            <Box sx={{
                display: "flex",
                zIndex: 300,
                flexDirection: "row",
                justifyContent: "space-between",
                bottom: 0,
                width: "100vw",
                height: "50px",
                position: "fixed",
                backgroundColor: "black",
                paddingLeft: 2,
                paddingRight: 2
            }}>
                <CommonSettings/>
                <BuyBlock/>
            </Box>}
            <Modal
                    open={lockedComics}
                    onClose={() => {setLockedComics(false)}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                <Box sx={modalStyle} backgroundColor={"white"}>
                    <ComicsLocked onClose={() => {setLockedComics(false)}}
                                  comics={comics}
                                  directBuy={false}
                                  currentUser={currentUser}
                                  userData={props.userData}/>
                </Box>
            </Modal>
        </Box>
     );
}
 
export default ReaderSettings;