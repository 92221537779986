import { useState, useEffect } from 'react';
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from '../utils/firebase';

var UrlsSingleton = (function () {
  var instance;

  function createInstance() {
      var object = new Object({"urls": {}});
      return object;
  }

  return {
      getInstance: function () {
          if (!instance) {
              instance = createInstance();
          }
          return instance;
      }
  };
})();

export const fetchImageAsync = async(imageUrl) => {
  return new Promise(resolve => {
    const stored = UrlsSingleton.getInstance();
  if (stored.urls[imageUrl] != null) {
        return resolve(stored.urls[imageUrl])
  } else {
     getDownloadURL(ref(storage, imageUrl)).then((url) => {
        stored.urls[imageUrl] = url
        return resolve(url)
     })
  }});
}

export const loadImage = (setImageDimensions, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setImageDimensions({
      height: img.height,
      width: img.width
    });
  };
  img.onerror = (err) => {
    console.error(err);
  };
};

const useImageFetch = (imageUrl) => {
    const [image, setImage] = useState(null);
    const stored = UrlsSingleton.getInstance();
    useEffect(() => {
      if (stored.urls[imageUrl] != null) {
         setImage(stored.urls[imageUrl])
      } else {
        const abortCont = new AbortController();
        getDownloadURL(ref(storage, imageUrl)).then((url) => {
          setImage(url)
          stored.urls[imageUrl] = url
        });
        // abort the fetch
        return () => abortCont.abort();
      }
    }, [imageUrl])
  
    return { image };
  }
   
  export default useImageFetch;