import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { COLORS } from "../values/values";
import { useTranslation } from 'react-i18next'

const AnnounceDateBlock = (props) => {
    const { t } = useTranslation()
    const comics = props.comics

    function AnnounceDate(props) {
        const comics = props.comics;
        if (comics.announceDate != null) {
            const current = new Date()
            if (comics.announceDate.getTime() < current.getTime()) {
                return <Typography fontSize={12} color="white">{t('not_soon')}</Typography>
            } else {
                const date = [
                    comics.announceDate.getFullYear(),
                    ('0' + (comics.announceDate.getMonth() + 1)).slice(-2),
                    ('0' + comics.announceDate.getDate()).slice(-2)
                  ].join('.');
                return <Typography fontSize={12} color="white">{date}</Typography>
            }
        } 
        return null
    }

    return (
            <Box>
                <Box sx={{display: 'inline-flex'}}>
                    <AnnounceDate comics={comics}/>
                </Box>
                <Box sx={{bgcolor: COLORS.green, borderRadius: 1, display: 'inline-flex', paddingRight: 0.5, paddingLeft: 0.5, marginLeft: 0.5}}>
                    <Typography fontSize={12} color="white">
                        {t('announce')}
                    </Typography>
                </Box>
            </Box> );
}
 
export default AnnounceDateBlock;