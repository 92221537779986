import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next'

export default function Copyright(props) {
  const { t } = useTranslation()
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {t('copyright')}
        <Link color="inherit" href="/">
          {t('zhevr')}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }