
import { Box, Skeleton } from '@mui/material';
import ComicsCover from '../items/ComicsCover';
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import { COLORS } from '../values/values';

const Featured = (props) => {
    const { t } = useTranslation()
    let comicses = props.comicses
    let location = useLocation()
    let ref = useRef()

    useEffect(()=> {
        if (location.hash && location.hash === "#comics" && ref) {
            ref.current.scrollIntoView({behavior: "smooth"})
        }
    }, [location,])

    return ( 
        <Box ref = {ref} sx={{maxWidth: "100%"}}>
            { comicses.length > 0 ? <Box id="featured" sx={{display: "inline-flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center"}}>
                {comicses.map( (comics) => (
                    <Box key={comics.id}>
                        <ComicsCover comics={comics}/>
                    </Box>
                )) }
            </Box> : <Skeleton variant="rectangular" sx={{
                        height: 300,
                        background: COLORS.skeleton,
                        width: "100%",
                    }} /> }
        </Box>
     );
}
 
export default Featured;