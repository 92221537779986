import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL } from "firebase/storage";
import { analytics, storage } from '../utils/firebase';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS, FONTS_FAMILY } from '../values/values';
import { useTranslation } from 'react-i18next'
import { logEvent } from 'firebase/analytics';

const StickersCover = (props) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const sticker = props.sticker
    
    const [cover, setCover] = useState("");

    useEffect(() => {
        getDownloadURL(ref(storage, sticker.cover)).then((url) => {
            setCover(url)
        }).catch((error) => {
            // Handle any errors
        })
    }, []);

    return (
        <Box textAlign={"center"} sx={{
            display: "inline-flex",
            paddingBottom: 1,
        }}>
            <a onClick={() => {logEvent(analytics, 'open_stickers', {"name": sticker.name.localized('en')});}} href={sticker.url} target="_blank" style={{textDecoration: "none"}}>
                { cover ? <Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        height: {xs: "calc(50vw - 28px)", sm: "calc(50vw - 28px)", md: 200, lg: 200, xl: 200 },
                        width: {xs: "calc(50vw - 28px)", sm: "calc(50vw - 28px)",  md: 200, lg: 200, xl: 200 },
                        maxHeight: 200,
                        maxWidth: 200
                    }}
                    alt={sticker.name.localized(lang)}
                    src={cover}
                    /> : <Skeleton variant="rectangular" sx={{
                        height: {xs: "calc(50vw - 28px)", sm: "calc(50vw - 28px)", md: 200, lg: 200, xl: 200 },
                        width: {xs: "calc(50vw - 28px)", sm: "calc(50vw - 28px)", md: 200, lg: 200, xl: 200 },
                        background: COLORS.skeleton,
                        maxHeight: 200,
                        maxWidth: 200
                    }} /> }
                    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <Box sx={{display: "inline-flex", justifyContent: "center"}}>
                            <FontAwesomeIcon style={{color: "white", paddingRight: 5, marginTop: 3}} fontSize={{xs: 12, sm: 12, md: 15}} icon={["fab", "telegram"]} />
                            <Typography sx={{color: "white", textTransform: "uppercase", fontFamily: FONTS_FAMILY.header}} fontSize={{xs: 14, sm: 14, md: 17}}>{sticker.name.localized(lang)}</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{color: "white", fontSize: 12}}>{sticker.count} {t('stickers')}</Typography>
                        </Box>
                    </Box>
            </a>
        </Box>
    );
}

export default StickersCover;