import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback } from "react";
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from "react-router-dom";
import { COLORS } from "../values/values";

const NewsPost = (props) => {
    const { t, i18n } = useTranslation()
    let lang = i18n.resolvedLanguage
    let news = props.news
    
    const navigate = useNavigate();
    const openPost = useCallback(() => navigate(`/post/${news.id}`, {replace: true}), [navigate]);

    function DateBlock() {
        const date = [
            ('0' + news.date.getDate()).slice(-2),
            ('0' + (news.date.getMonth() + 1)).slice(-2),
            news.date.getFullYear()
          ].join('.');
        return <Typography fontSize={12} color={COLORS.yellow}>{date}</Typography>
    }

    return (<Box>
        <Link to={`/post/${news.id}`} style={{ textDecoration: 'none', color: "white"}}>
            <Box>
                <Typography variant="h6" color="white">{news.title.localized(lang)}</Typography>
                <DateBlock/>
            </Box>
        </Link>
        <Button onClick={openPost}
            sx={{padding:0,
                minHeight: 0,
                minWidth: 0,}}>
            <Box
                component="img"
                sx={{
                    objectFit: "cover",
                    maxWidth: "100%",
                }}
                alt={news.title.localized(lang)}
                src={news.image}
                />
        </Button>
        <div style={{color: "white", fontSize: 14, width: "100%"}} dangerouslySetInnerHTML={{__html: news.content.localized(lang)}}></div>
    </Box> );
}
 
export default NewsPost;