import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next"

const TagsComponent = (props) => {
    const { t } = useTranslation()
    const comics = props.comics
    const fontSize = props.fontSize

    function tagsList() {
        let tags = []

        comics.tags.forEach(tag => {
            if (tag === "adventure") {
                tags.push(t('tag_adventure'))
            }
            if (tag === "fantasy") {
                tags.push(t('tag_fantasy'))
            }
        });
        return tags
    }

    function textTags() {
        let tags = tagsList()
        return tags.join(" / ")
    }

    return ( <Box>
        <Typography sx={{color: "white", fontSize: fontSize ? fontSize : 12, display: "inline-flex"}}>
            {textTags()}
        </Typography>
    </Box> );
}
 
export default TagsComponent;