import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { COLORS } from "../values/values";
import logo_text_white from '../images/logo_text_white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import download from '../images/download.png'
import liqpay_transparent from '../images/liqpay_transparent.png'
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import SocialComponent from "../details/SocialComponent";

const Footer = (props) => {
    const { t } = useTranslation()

    const navigate = useNavigate();
    const openHomeComics = useCallback(() => navigate('/#comics', {replace: true}), [navigate]);
    const openHomeWallpapers = useCallback(() => navigate('/#wallpapers', {replace: true}), [navigate]);
    const openHomeStickers = useCallback(() => navigate('/#stickers', {replace: true}), [navigate]);
    const openPublicOffer = useCallback(() => navigate('/offer', {replace: true}), [navigate]);
    const openPrivacyPolicy = useCallback(() => navigate('/privacy', {replace: true}), [navigate]);
    const openDelivery = useCallback(() => navigate('/delivery', {replace: true}), [navigate]);
    const openAboutUs = useCallback(() => navigate('/aboutus', {replace: true}), [navigate]);
    const openNews = useCallback(() => navigate('/#news', {replace: true}), [navigate]);
    
    const onOpenSupport = () => {
      props.innerRef.current.doOpenSupport()
    }

    function DownloadBlock(props) {
      return <Box>
          <Box>
                <Typography sx={{paddingBottom: 1}} fontWeight="bold">{t('download_zhevr_now')}</Typography>
                <Box
                  component="img"
                  sx={{
                      objectFit: "cover",
                      maxHeight: "40px"
                  }}
                  alt={t('download_zhevr_now')}
                  src={download}
                  />
          </Box>
      </Box>
    }

    return ( <footer>
          <Box textAlign="center" sx={{bgcolor: COLORS.blue, color: "white", paddingTop: 4, paddingBottom: 8}}>
          <Container maxWidth="md">
          <SocialComponent item={{facebook: "https://www.facebook.com/svityart", instagram: "https://www.instagram.com/svity_studios/"}}/>
              <Box sx={{paddingTop: 3, display: "flex", justifyContent: "center", flexWrap: "wrap"}} flexDirection={{xs: "column", sm: "row"}}>
                  <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={openHomeComics}>
                      {t('comics_header')}
                  </Button>
                  <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={openHomeWallpapers}>
                      {t('wallpapers_header')}
                  </Button>
                  <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={openHomeStickers}>
                      {t('stickers_header')}
                  </Button>
                  <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={openNews}>
                      {t('news')}
                  </Button>
                  <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={openPublicOffer}>
                    {t('public_offer')}
                  </Button>
                  <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={openPrivacyPolicy}>
                    {t('privacy_policy')}
                  </Button>
                  <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={openAboutUs}>
                    {t('about_us')}
                  </Button>
                  <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={openDelivery}>
                    {t('delivery_and_payment')}
                  </Button>
                  <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={onOpenSupport}>{t('support')}</Button>
              </Box>
              <Box sx={{paddingTop: 4}}>
                {/**<Box
                  component="img"
                  sx={{
                      objectFit: "cover",
                      height: 30
                  }}
                  alt={t('zhevr')}
                  src={logo_text_white}
                  />
                <Typography sx={{fontSize: 12}}>
                    {t('zhevr_description')}
                </Typography>**/}
                <Box
                    component="img"
                    sx={{width: "250px"}}
                    alt={t('LiqPay, VISA, Mastercard')}
                    src={liqpay_transparent}
                    />
              </Box>
              <Box sx={{paddingTop: 4}}>
                <Typography sx={{fontSize: 12}}>
                  {t('zhevr')} &reg; {new Date().getFullYear()}
                </Typography>
              </Box>
          </Container>
          </Box>
      </footer> );
}
 
export default Footer;