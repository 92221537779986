import TranslatableText from './TranslatableText.js'

export default class House {
    constructor(id, data) {
        this.type = "house"
        this.id = id
        this.city = new TranslatableText(data.city)
        this.comicses = data.comicses
        this.facebook = data.facebook
        this.instagram = data.instagram
        this.patreon = data.patreon
        this.folder = data.folder
        this.name = new TranslatableText(data.name)
        this.synopsis = new TranslatableText(data.synopsis)
        this.website = data.website
        if (data.birthday != null) {
            this.birthday = new Date(data.birthday.seconds * 1000)
        }
    }

    get cover() {
        return 'houses/' + this.folder + '/cover.jpg'
    }
}