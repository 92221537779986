import { Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import StickersCover from '../items/StickersCover';
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { COLORS } from '../values/values';

const StickersListing = (props) => {
    const { t } = useTranslation()
    let stickers = props.stickers
    let location = useLocation()
    let ref = useRef()

    useEffect(()=> {
        if (location.hash && location.hash === "#stickers" && ref) {
            ref.current.scrollIntoView({behavior: "smooth"})
        }
    }, [location,])

    return ( 
        <Box ref={ref}>
            <Typography sx={{color: "white", paddingBottom: 1, fontSize: 20}}>{t('stickers_header')}</Typography>
            {stickers.length > 0 ? <Box sx={{alignItems: 'baseline', display: 'inline-flex', flexWrap: "wrap", justifyContent: "center"}}>
                {stickers.map( (sticker) => (
                    <Box key={sticker.id} sx={{paddingRight: 1.5}}>
                        <StickersCover sticker={sticker}/>
                    </Box> 
                )) } 
            </Box> : <Skeleton variant="rectangular" sx={{
                        height: 200,
                        width: 840,
                        background: COLORS.skeleton 
                    }} />}
        </Box>
     );
}
 
export default StickersListing;