import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from 'react-i18next'
import mascot_support from '../images/mascot_support.png'

const SupportPage = (props) => {
    const { t } = useTranslation()

    const iconStyle = {
        color: "black",
        fontSize: 16
    }

    return ( <Box sx={{width: 300}}>
        <Box sx={{display: "flex", flexDirection: "row", alignContent: "center", justifyContent: 'space-between', paddingBottom: 2}}>
                <Typography sx={{fontSize: 20, fontWeight: "bold"}}>{t('support')}:</Typography>
                <Button sx={{ color: "black", minWidth: "44px", fontSize: 18}} onClick={props.onClose}>
                    <FontAwesomeIcon icon={["fas", "xmark"]} />
                </Button>
        </Box>
        <Box textAlign={"center"}>
            <Box
                component="img"
                sx={{
                    objectFit: "cover",
                    height: 200,
                    width: 200,
                }}
                alt={t('support')}
                src={mascot_support}/>
            <Typography sx={{paddingBottom: 1}}>{t('support_contact_us')}</Typography>
            <Box sx={{paddingBottom: 1}}>
                <Link sx={iconStyle} underline="none" href={"mailto:yaroslav.svit.comx@gmail.com"}>
                    <Box display={"flex"} justifyContent={"center"}>
                        <FontAwesomeIcon style={{paddingRight: 5, marginTop: 5}} icon={["fa", "envelope"]} />
                        <Typography>zhevr.comics@gmail.com</Typography>
                    </Box>
                </Link>
            </Box>
            <Box sx={{paddingBottom: 1}}>
                <Link sx={iconStyle} underline="none" href={"https://www.facebook.com/svityart"}>
                    <FontAwesomeIcon icon={["fab", "facebook"]} /> Facebook
                </Link>
            </Box>
            <Box sx={{paddingBottom: 1}}>
                <Link sx={iconStyle} underline="none" href={"https://www.instagram.com/svity_studios/"}>
                    <FontAwesomeIcon icon={["fab", "instagram"]} /> Instagram
                </Link>
            </Box>
        </Box>
    </Box> );
}
 
export default SupportPage;