import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { COLORS } from "../values/values";

const NewsSkeleton = () => {
    return ( <Box>
        <Skeleton variant="rectangular" sx={{
            height: 40,
            bgcolor: COLORS.skeleton,
            width: "100%",
            marginBottom: 1,
            borderRadius: 1
        }} />
        <Skeleton variant="rectangular" sx={{
            height: 200,
            bgcolor: COLORS.skeleton,
            width: "100%",
            marginBottom: 3,
            borderRadius: 1
        }} />
    </Box> );
}
 
export default NewsSkeleton;