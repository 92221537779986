import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Copyright from './Copyright';
import logo from '../images/logo.png'
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { COLORS } from '../values/values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { auth } from '../utils/firebase';
import { sendEmailVerification, signOut } from 'firebase/auth';
import Loader from '../tools/Loader';

const VerifyEmail = (props) => {
  const { t } = useTranslation()
  const [info, setInfo] = useState(null)
  const [buttonEnabled, setButtonEnabled] = useState(true)
  const [countdown, setCountdown] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const goSignIn = () => { 
    signOut(auth).then(() => {
            props.updateMode("signin")
        }).catch((error) => {
            props.updateMode("signin")
        });
    }

  const sentLink = () => {
        if (auth.currentUser) {
            sendEmailVerification(auth.currentUser)
                .then(() => {
                    setInfo(t('verify_link_sent'))
                    setButtonEnabled(false)
                    startCountDown()
                    setIsLoading(false)
                }).catch((error) => {
                    setIsLoading(false)
                });
        } else {
            props.updateMode("signin")
        }
  }

  const startCountDown = () => {
        setCountdown(30)
  }

  useEffect(() => {
    if (props.send) {
        sentLink()
    } else {
        setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (countdown <= 30 && countdown > 0) {
        const interval = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearInterval(interval);
    } else {
        if (countdown === 0) {
            setButtonEnabled(true)
            setInfo(null)
        }
    }
  }, [countdown]);

  return (
    <Box>
      {props.onClose && 
        <Button sx={{ color: "black",
                      minWidth: "44px",
                      fontSize: 18,
                      position: "absolute",
                      right: 20}} 
                onClick={ () => {
                    signOut(auth).then(() => {
                        props.onClose()
                    }).catch((error) => {
                        props.onClose()
                    });
                }}>
            <FontAwesomeIcon icon={["fas", "xmark"]} />
        </Button>}
      
      <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
                component="img"
                sx={{
                    objectFit: "cover",
                    height: 50,
                    width: 50,
                    borderRadius: 25
                }}
                alt={t('zhevr')}
                src={logo}
                />
            <Typography sx={{paddingTop: 1, fontWeight: "700"}}>{t('verify_email')}</Typography>
            <Typography sx={{paddingTop: 1, paddingBottom: 1, fontSize: 14, textAlign: "center"}}>{t('verify_email_to_finish')}</Typography>
        { isLoading ? <Box paddingBottom={2}>
                <Loader color={COLORS.blue}/>
            </Box> : 
            <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                { info && <Typography 
                            sx={{paddingTop: 1,
                                paddingBottom: 1,
                                fontSize: 14,
                                color: COLORS.blue,
                                textAlign: "center"}}>{info}</Typography>}
                <Button disabled={!buttonEnabled} sx={{color: "white",
                            backgroundColor: COLORS.blue,
                            "&:hover": {
                                background: COLORS.button_hover,
                                },
                                "&:disabled": {
                                color: "white",
                                background: "gray"
                                }}}
                        onClick={sentLink}>
                        <Typography>{(buttonEnabled ? "" : "(" + countdown + ") ") + t('get_verification_link')}</Typography>
                </Button>
                <Button sx={{marginTop: 3}} onClick={goSignIn}>
                        <Typography sx={{color: COLORS.blue, fontSize: 12, fontWeight: 600}}>
                        {t('signin')}
                        </Typography>
                    </Button>
                    </Box>}
          </Box>
    </Box>
  )
}
 
export default VerifyEmail;