
import React, { useEffect, useState } from 'react';
import { fetchUserData } from '../fetch/userDataFetch';
import { collection, query, where, getDocs, documentId } from "firebase/firestore";
import { auth, db } from '../utils/firebase';
import Comics from "../models/Comics";
import AddedComicsCover from './AddedComicsCover';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system';
import { COLORS, FONTS_FAMILY } from '../values/values';
import mascot_read from '../images/mascot_read.png'
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const AccountLibrary = (props) => {
    const { t } = useTranslation()
    const [comicses, setComicses] = useState([])
    const [userData, setUserData] = useState(null)
    const [user, setUser] = useState(null)
    
    const navigate = useNavigate();
    const goToHome = useCallback(() => {
        const path = "/"
        navigate(path, {replace: true}) 
    }, [navigate]);

    const fetchMyComics = async() => {
        if (haveMyComicses()) {
            const q = query(collection(db, "comics"), where(documentId(), "in", userData.myComics));
            const a = []
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                let comics = new Comics(doc.id, doc.data()) 
                a.push(comics)
            })
            setComicses(a)  
        }
    }

    const haveMyComicses = () => {
        return userData != null && userData.myComics && userData.myComics.length > 0
    }

    useEffect(() => {
        setUser(auth.currentUser)
        auth.onAuthStateChanged(user => {
            if (user) {
                if (user.emailVerified === false) {
                    goToHome()
                } else {
                    setUser(user);
                }
            } else {
                goToHome()
            }
        })
    }, [])

    useEffect(() => {
        if (user) {
            async function fetchData() {
                // You can await here
                const user = await fetchUserData();
                setUserData(user)
            }
            fetchData();
        }
    }, [user]);

    useEffect(() => {
        fetchMyComics()
    }, [userData]);

    const onEditAccount = () => {
        props.innerRef.current.doEditAccount()
    }

    const onAvatarSelect = () => {
        props.innerRef.current.doSelectAvatar()
    }

    function ComicsBlock() {
        if (haveMyComicses()) {
            return <Container maxWidth="md">
                        <Box sx={{justifyContent: "center", flexDirection: "row", display: "flex", flexWrap: "wrap"}}>
                            {comicses.map( (comics) => (
                                <Box key={comics.id} sx={{paddingRight: 3}}>
                                    <AddedComicsCover comics={comics}/>
                                </Box>
                            )) }        
                        </Box>
                    </Container>
        } else {
            return <Box textAlign={"center"}>
                <Typography sx={{color: "white", 
                fontFamily: FONTS_FAMILY.header, 
                fontSize: 20, paddingBottom: 1}}>{t('empty_library')}</Typography>
                <Button onClick={goToHome} sx={{marginRight: 1, 
                        color: "white",
                        background: COLORS.blue,
                        '&:hover': {
                            backgroundColor: COLORS.button_hover,
                            color: "white",
                        }}}>
                    <Typography sx={{fontSize: 14}} textAlign="center">{t("go_to_store")}</Typography>
                </Button>       
            </Box>
        }
        
    }

    return ( <Box sx={{minHeight: '90vh'}}>
        { userData && user && 
            <Box>
                <Box sx={{background: COLORS.blue}}>
                    <Container maxWidth="md">
                        <Box sx={{justifyContent: "center", display: "flex", paddingTop: 4, paddingBottom: 4, flexDirection: "row"}}>
                            <Box sx={{marginRight: 2}}>
                                <Box
                                    component="img"
                                    sx={{
                                        objectFit: "cover",
                                        height: 120,
                                        width: 120,
                                    }}
                                    alt={t('this_is_your_library')}
                                    src={mascot_read}
                                    />
                            </Box>
                            <Box>
                                <Typography sx={{color: "white", fontFamily: FONTS_FAMILY.header, fontSize: 24}}>{t('greetings')}, {auth.currentUser.displayName}</Typography>
                                <Typography sx={{color: "white", fontFamily: FONTS_FAMILY.header, fontSize: 24}}>{t('this_is_your_library')}</Typography>
                                <Box sx={{paddingTop: 1, display: "flex", flexDirection: "row"}}>
                                <Button onClick={onAvatarSelect} sx={{marginRight: 1, 
                                        color: "white",
                                        background: "black",
                                        '&:hover': {
                                            backgroundColor: COLORS.button_hover,
                                            color: "white",
                                        }}}>
                                    <Typography sx={{fontSize: {xs: 10, sm: 10, md: 12, lg: 12, xl: 12}}} textAlign="center">{t('change_avatar')}</Typography>
                                </Button>
                                <Button onClick={onEditAccount} sx={{color: "white", 
                                        background: "black",
                                        '&:hover': {
                                            backgroundColor: COLORS.button_hover,
                                            color: "white",
                                        }}}>
                                    <Typography sx={{fontSize: {xs: 10, sm: 10, md: 12, lg: 12, xl: 12}}} textAlign="center">{t('edit_profile')}</Typography>
                                </Button>
                            </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Box sx={{paddingTop: 4, paddingBottom: 4}}>
                    <ComicsBlock/>
                </Box>
            </Box>
        }
        </Box> );
}
 
export default AccountLibrary;