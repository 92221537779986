// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getRemoteConfig } from "firebase/remote-config";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVDvjTBIReHtVLEP1zGJUx4BVWIIBtZSE",
  authDomain: "zevr-6657a.firebaseapp.com",
  databaseURL: "https://zevr-6657a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "zevr-6657a",
  storageBucket: "zevr-6657a.appspot.com",
  messagingSenderId: "803511980784",
  appId: "1:803511980784:web:f35f365a1e6a1e59bd2835",
  measurementId: "G-BPHPN7Q05L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => signInWithPopup(auth, provider);
//Remote config
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  "comicses": [
    "VaUVVnYC0UzSRkIvxUuc",
    "Fmve3oMNs1TvrLSklZj3",
    "yA5pV0Gm1Un4M056OMCG",
    "EMuNcLkN8Eb2JelceFMN"
  ],
  "showPublishingHouses": true,
  "newReleases": [
    "Fmve3oMNs1TvrLSklZj3"
  ]
};
export const functions = getFunctions(app)
//connectFunctionsEmulator(functions, "localhost", 5001);