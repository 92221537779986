import { Box, Typography } from "@mui/material";
import { t } from "i18next";

const InfoBlocks = (props) => {
    const comics = props.comics

    const boxStyle = {
        textAlign: "right",
        flexDirection: "column",
        marginBottom: 1,
        display: "flex"
    }

    const topTextStyle = {
        color: "white",
        fontSize: {xs: 14, sm: 14, md: 18, lg: 18, xl: 18},
        lineHeight: 1
    }

    const bottomTextStyle = {
        color: "white",
        fontSize: {xs: 12, sm: 12, md: 14, lg: 14, xl: 14},
    }


    function ReleaseDate(props) {
        const comics = props.comics;

        if (comics.releaseDate != null) {
            return <Box sx={boxStyle}>
                    <Typography sx={topTextStyle}>{comics.releaseDate.getFullYear()}</Typography>
                    <Typography sx={bottomTextStyle}>{t('year')}</Typography>
                </Box>
        } 
        return null
    }

    return ( <Box sx={{flexDirection: "column"}}>
        { comics.pageCount > 0 && 
        <Box sx={boxStyle}>
            <Typography sx={topTextStyle}>{comics.pageCount}</Typography>
            <Typography sx={bottomTextStyle}>{t('pages')}</Typography>
        </Box>}
        { comics.ageRestrictions && 
        <Box sx={boxStyle}>
            <Typography sx={topTextStyle}>{comics.ageRestrictions}</Typography>
            <Typography sx={bottomTextStyle}>{t('age')}</Typography>
        </Box>}
        { comics.releaseDate && 
        <ReleaseDate comics={comics}/>}
    </Box> );
}
 
export default InfoBlocks;