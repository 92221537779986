import { useTranslation } from 'react-i18next'

export default class TranslatableText {
    
    constructor(data) {
        this.translations = data.translations
    }

    localized(lang) {
        if (lang === 'uk') {
            return this.translations.uk;
        } else {
            return this.translations.en;
        }
    }
}
