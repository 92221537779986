import { Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next'

const ReaderReadMode = (props) => {
    const { t } = useTranslation()

    const setClassicMode = () => {
        props.setClassic()
        handleCloseMenu()
    };

    const setWebtoonMode = () => {
        props.setWebtoon()
        handleCloseMenu()
    }

    useEffect(() => {
    }, []);

    const [anchorElMode, setAnchorElMode] = React.useState(null);
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMode(event.currentTarget);
      };
    
    const handleCloseMenu = () => {
        setAnchorElMode(null);
    };
    
    return ( <Box sx={{paddingRight: "10px"}}>
        <Tooltip title="Open settings">
            <Button sx={{color: "white", height: "50px"}} onClick={handleOpenMenu}>
                <Typography sx={{fontSize: 14, textTransform: "capitalize"}}>{t('mode')} {props.mode === "classic" ? t('read_mode_classic') : "Webtoon"}</Typography>
            </Button>
        </Tooltip>
        <Menu
            id="menu-appbar"
            anchorEl={anchorElMode}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElMode)}
            onClose={handleCloseMenu}
            >

            <MenuItem key="classic" onClick={setClassicMode}>
                <Typography textAlign="center">{t('read_mode_classic')}</Typography>
            </MenuItem>
            <MenuItem key="webtoon" onClick={setWebtoonMode}>
                <Typography textAlign="center">Webtoon</Typography>
            </MenuItem>
            </Menu>
    </Box> );
}
 
export default ReaderReadMode;