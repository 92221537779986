import TranslatableText from './TranslatableText.js'

export default class News {
    constructor(id, data) {
        this.id = id
        this.content = new TranslatableText(data.content)
        this.summary = new TranslatableText(data.summary)
        this.title = new TranslatableText(data.title)
        this.authorId = data.author.id
        this.authorName = new TranslatableText(data.author.name)
        this.image = data.image
        if (data.date != null) {
            this.date = new Date(data.date.seconds * 1000)
          }
    }
}