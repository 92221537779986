import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, documentId } from "firebase/firestore";
import { db } from '../utils/firebase';
import Author from "../models/Author";
import House from '../models/House';

export const fetchAuthors = async(list, type) => {
  let ids = []
  if (list != null) {
    list.forEach(element => {
        ids.push(element.id)
    })
    if (ids.length > 0) {
        const q = query(collection(db, type), where(documentId(), "in", ids));
        const a = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          if (type === "author") {
            let author = new Author(doc.id, doc.data()) 
            a.push(author)
          } else {
            let house = new House(doc.id, doc.data()) 
            a.push(house)
          }
        })
        return Promise.resolve(a)
    } else {
        return Promise.resolve([])
    }
  }
}

const useAuthorsFetch = (list, type) => {
    const [authors, setAuthors] = useState(null);
    const [houses, setHouses] = useState(null);
  
    const fetchAuthors = async(list, type) => {
      let ids = []
      if (list != null) {
        list.forEach(element => {
            ids.push(element.id)
        })
        if (ids.length > 0) {
            const q = query(collection(db, type), where(documentId(), "in", ids));
            const a = []
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              if (type === "author") {
                let author = new Author(doc.id, doc.data()) 
                a.push(author)
              } else {
                let house = new House(doc.id, doc.data()) 
                a.push(house)
              }
            })
            if (type === "author") {
              setAuthors(a)
            } else {
              setHouses(a)
            }
        }
      }
    }

    useEffect(() => {
      const abortCont = new AbortController();
      fetchAuthors(list, type)
      // abort the fetch
      return () => abortCont.abort();
    }, [])
  
    return { authors, houses };
  }
   
  export default useAuthorsFetch;