import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from 'react-i18next'
import { COLORS } from "../values/values";
import tutorial1 from '../images/tutorial1.jpg'
import tutorial2 from '../images/tutorial2.jpg'
import tutorial3 from '../images/tutorial3.jpg'
import tutorial4 from '../images/tutorial4.jpg'
import tutorial5 from '../images/tutorial5.jpg'

const ReaderTutorial = (props) => {
    const { t } = useTranslation()
    const [step, setStep] = useState(0)
    let maxPage = 4

    const paginate = (index) => {
        if (step === maxPage) {
            props.onClose()
        } else {
            setStep(step + index)
        }
    }

    function StepHeader() {
        return <Box>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <Typography sx={{
                        fontWeight: "bold",
                        fontSize: 20
                    }}>{t(`tutorial_header_${step + 1}`)}</Typography>
                </motion.div>
            </AnimatePresence>
        </Box>
    }

    function StepImage() {
        let img = tutorial1
        if (step === 1) {
            img = tutorial2
        }
        if (step === 2) {
            img = tutorial3
        }
        if (step === 3) {
            img = tutorial4
        } 
        if (step === 4) {
            img = tutorial5
        }

        return <Box sx={{textAlign: "center"}} onClick={() => {paginate(1)}}>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <Box
                        
                        component="img"
                        sx={{
                            objectFit: "cover",
                            width: "70vw",
                            height: "70vw",
                            maxWidth: "250px",
                            maxHeight: "250px"
                        }}
                        alt="ЖЕВР"
                        src={img}
                        />
                </motion.div>
            </AnimatePresence>
        </Box>
    }

    function StepBlock() {
        return <Box sx={{paddingBottom: 2}}  onClick={() => {paginate(1)}}>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <Typography sx={{
                        textAlign: "center",
                        fontSize: 14
                    }}>{t(`tutorial_description_${step + 1}`)}</Typography>
                </motion.div>
            </AnimatePresence>
        </Box>
    }

    return ( <Box sx={{color: "white", width: "100vw", maxWidth: "350px"}}>
        <Box sx={{display: "flex", 
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: 'space-between',
                  paddingBottom: 2}}>
            <StepHeader/>
            <Button sx={{ color: "white", minWidth: "44px", fontSize: 18}} onClick={props.onClose}>
                <FontAwesomeIcon icon={["fas", "xmark"]} />
            </Button>
        </Box>
        <StepBlock/>
        <StepImage/>
        <Typography sx={{textAlign: "center", paddingBottom: 2}}>{step + 1} / {maxPage + 1}</Typography>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
            { step !== 0 ? 
                <Button onClick={() => {paginate(-1)}}
                    sx={{color: "white"}}>
                    <Typography sx={{fontWeight: "bold", fontSize: 12}}>{t('back')}</Typography>
                </Button> : <Box></Box> }
            <Button onClick={() => {paginate(1)}}
                sx={{color: COLORS.blue,
                    backgroundColor: "white",
                    "&:hover": {
                        background: "white",
                    }}}>
                <Typography sx={{fontWeight: "bold", fontSize: 12}}>{step !== maxPage ? t('next') : t('finish')}</Typography>
            </Button>
        </Box>
    </Box> );
}
 
export default ReaderTutorial;