import { addDoc, collection, doc } from 'firebase/firestore'
import { auth, db } from './firebase'
import { SERVICE_KEYS } from './keys'

const CryptoJS = require('crypto-js')

export const generateLiqPayScript = async(comics) => {
    //generate order id
    let order = {
        name: comics.name.translations["en"],
        email: auth.currentUser.email,
        comicsId: comics.id,
        userId: auth.currentUser.uid,
        status: "waiting"
    }
    const docRef = await addDoc(collection(db, "orders"), order);   
    let orderId = docRef.id
    let privateKey = SERVICE_KEYS.liqpay_private
    const jsonString = {
        public_key: SERVICE_KEYS.liqpay_public,
        version: '3',
        action: 'pay',
        server_url: "https://us-central1-zevr-6657a.cloudfunctions.net/processPayment",
        amount: comics.price.amount,
        currency: comics.price.currency,
        description: comics.name.translations["uk"],
        order_id: orderId,
    }
    console.log(jsonString)
    const data = utf8_to_b64(JSON.stringify(jsonString))
    const signString = privateKey + data + privateKey
    const signature = CryptoJS.enc.Base64.stringify(CryptoJS.SHA1(signString))
    
    let liqpay = {
        data: data,
        signature: signature
    }
    return Promise.resolve(liqpay);
}

export function utf8_to_b64(str) {
  const encodedWord = CryptoJS.enc.Utf8.parse(str);
  const encoded = CryptoJS.enc.Base64.stringify(encodedWord);
  return encoded;
}