import { COLORS } from '../values/values.js'
import Page from './Page.js'
import Role from './Role.js'
import TranslatableText from './TranslatableText.js'

export default class Comics {
  constructor(id, data) {
    this.id = id
    this.folder = data.folder
    if (data.announceDate != null) {
      this.announceDate = new Date(data.announceDate.seconds * 1000)
    }
    if (data.releaseDate != null) {
      this.releaseDate = new Date(data.releaseDate.seconds * 1000)
    }
    this.pageCount = data.pageCount
    this.name = new TranslatableText(data.name)
    this.facebook = data.facebook
    this.history = new TranslatableText(data.history)
    this.instagram = data.instagram
    this.synopsis = new TranslatableText(data.synopsis)
    this.trailer = new TranslatableText(data.trailer)
    this.visible = data.visible
    this.website = data.website
    this.freePages = data.freePages
    this.paperVersion = data.paperVersion
    this.premium = data.premium
    this.patreon = data.patreon
    this.interactive = data.interactive
    this.price = data.price
    if (data.quickNote != null) {
      this.quickNote = new TranslatableText(data.quickNote)
    }
    if (data.note != null) {
      this.note = new TranslatableText(data.note)
    }
    this.thumbnail = data.thumbnail
    this.colorScheme = data.colorScheme
    this.tags = data.tags
    this.ageRestrictions = data.ageRestrictions
    this.translations = data.translations
    if (data.authors != null) {
        let roles = []
        data.authors.forEach(element => {
            let r = new Role(element)
            roles.push(r)
        })
        this.authors = roles
    }
    if (data.publishingHouses != null) {
      let roles = []
      data.publishingHouses.forEach(element => {
          let r = new Role(element)
          roles.push(r)
      })
      this.houses = roles
    } 
    let pages = []

    for (let i = 0; i < this.pageCount; i++) {
        pages.push(new Page(i, this.folder))
    }
    this.pages = pages
    this.isNewRelease = false
  }

  isPageOpen(number) {
    return (number < this.freePages)
  }

  get schemeBackgroundColor() {
      if (this.colorScheme) {
        switch(this.colorScheme) {
          case "yellow":
            return COLORS.yellow;
          case "purple":
            return COLORS.purple;
          case "green":
            return COLORS.green;
          case "red":
            return COLORS.red;
          default:
            return COLORS.red;
        }
      } else {
        return COLORS.red
      }
  }

  get cover() {
    return 'comicses/' + this.folder + '/cover/uk/cover_small.jpg'
  }
  
  get coverBig() {
    return 'comicses/' + this.folder + '/cover/uk/cover_big.jpg'
  }
}