import React, { useRef } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AccountLibrary from "./account/AccountLibrary";
import PublicOffer from "./account/PublicOffer";
import ComicsDetails from "./details/ComicsDetails";
import CreatorDetails from "./details/CreatorDetails";
import PagesList from "./details/PagesList";
import GalleryDetails from "./gallery/GalleryDetails";
import Home from "./Home";
import DonateButton from "./navbar/Donate";
import Footer from "./navbar/Footer";
import Navbar from "./navbar/Navbar";
import NewsDetails from "./news/NewsDetails";
import NewsList from "./news/NewsList";
import AboutUs from "./account/AboutUs";
import DeliveryAndPayment from "./account/DeliveryAndPayment";
import PrivacyPolicy from "./account/PrivacyPolicy";

function MainLayoutRoutes() {
    let navRef = useRef()
    return (<React.Fragment>
            <Navbar innerRef={navRef}/>
            <DonateButton/>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/news" element={<NewsList/>}/>
                <Route path="/post/:id" element={<NewsDetails />}/>
                <Route path="/comics/:id" element={<ComicsDetails />}/>
                <Route path="/creator/:type/:id" element={<CreatorDetails />}/>
                <Route path="/gallery/:id" element={<GalleryDetails innerRef={navRef} />}/>
                <Route path="/pages/:id" element={<PagesList />}/>
                <Route path="/offer" element={<PublicOffer />}/>
                <Route path="/privacy" element={<PrivacyPolicy />}/>
                <Route path="/delivery" element={<DeliveryAndPayment />}/>
                <Route path="/aboutus" element={<AboutUs />}/>
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route path="/myaccount" element={<AccountLibrary innerRef={navRef}/>}/>
            </Routes>
            <Footer innerRef={navRef}/>
    </React.Fragment>)
}

export default MainLayoutRoutes;