import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Copyright from './Copyright';
import mascot_login from '../images/mascot_login.png'
import GoogleButton from 'react-google-button'
import { signInWithGoogle, auth } from '../utils/firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { COLORS } from '../values/values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcceptingAggreements from './AcceptingAggreements';

export default function LoginForm(props) {
  const { t } = useTranslation()

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')
    const password = data.get('password')
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          if (user.emailVerified === false) {
              props.updateMode("verify")
          }
    })
    .catch((error) => {
        const errorMessage = error.message;
        setError(errorMessage);
    });
  };

  const [error, setError] = React.useState(null)

  const goSignUp = () => { props.updateMode("signup") }
  const goForgotPassword = () => { props.updateMode("forgotpassword")}

  return (
    <Box width={"80vw"} maxWidth={368}>
      {props.onClose && 
      <Button sx={{ color: "black", minWidth: "44px", fontSize: 18, position: "absolute", right: 20}} onClick={props.onClose}>
        <FontAwesomeIcon icon={["fas", "xmark"]} />
      </Button>}

      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        > 
          {props.info && <Typography sx={{color: "red"}}>
            {props.info}
            </Typography>}
          <Box
            component="img"
            sx={{
                objectFit: "cover",
                width: {xs: 120, sm: 120, md: 150, lg: 150, xl: 150},
                height: {xs: 120, sm: 120, md: 150, lg: 150, xl: 150}
            }}
            alt={t("zhevr")}
            src={mascot_login}
            />
            <GoogleButton
                label={t("signin_with_google")}
                onClick={signInWithGoogle}
              />
          <Typography sx={{paddingTop: 3, fontWeight: "700"}}>{t('or')}</Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('email')}
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            { error && <Typography sx={{color: "red", fontSize: 10}}>{error}</Typography>}
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t('remember_me')}
          />*/}
          <Box sx={{display:"flex",
                    justifyContent: "space-between", 
                    flexDirection:"row"}}>
              <Button onClick={goForgotPassword}>
                <Typography  sx={{color: COLORS.blue, fontSize: 12, fontWeight: 600}}>
                  {t('forgot_password_question')}
                </Typography>
              </Button>
              <Button onClick={goSignUp} sx={{textAlign: "right"}}>
                  <Typography sx={{color: COLORS.blue, fontSize: 12, fontWeight: 600}}>
                    {t('dont_have_account')}
                  </Typography>
                </Button>
          </Box>
            <Box sx={{paddingTop: 2, paddingBottom: 2, display: "flex", flexDirection: "column", alignItems: 'center'}}>
              <Button
                type="submit"
                variant="contained"
                sx={{minWidth: "240px", marginBottom: 2, bgcolor: COLORS.blue}}
              >
                {t('signin')}
              </Button>
              <AcceptingAggreements/>
            </Box>
          </Box>
        </Box>
        <Copyright sx={{paddingTop: 2}} />
    </Box>
  );
}