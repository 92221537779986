import React from 'react';
import { Link } from 'react-router-dom';
import AnnounceDateBlock from '../details/AnnounceDateBlock';
import useImageFetch from '../fetch/imageFetch';
import { Box } from '@mui/system';
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import { COLORS, FONTS_FAMILY } from '../values/values';
import { useTranslation } from 'react-i18next'
import TagsComponent from '../details/TagsComponent';
import NewReleaseBlock from '../details/NewReleaseBlock';

const ComicsCover = (props) => {
    const comics = props.comics
    const image  = comics.thumbnail
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage

    return (
        <Box>
            <Box sx={{
                  width: {xs: "90vw", sm: 350, md: 400, lg: 400, xl: 400},
                  alignItems: 'baseline',
                  paddingBottom: 1,
                  paddingRight: 1
                }}>
                <Box sx={{display: "inline-flex", flexDirection: "row", justifyContent: "center"}}>
                    <Link to={`/comics/${comics.id}`} style={{ textDecoration: 'none', color: "white"}}>
                        <Box sx={{paddingRight: 2}}>
                            { image ? <Box
                                component="img"
                                sx={{
                                    objectFit: "cover",
                                    height: {xs: "calc(75vw)", sm: "calc(75vw)", md: 300, lg: 300, xl: 300 },
                                    width: {xs: "calc(50vw)", sm: "calc(50vw)",  md: 200, lg: 200, xl: 200 },
                                    maxHeight: 300,
                                    maxWidth: 200
                                }}
                                alt={comics.name.localized(lang)}
                                src={image}
                                /> : <Skeleton variant="rectangular" sx={{
                                    height: {xs: "calc(75vw)", sm: "calc(75vw)", md: 300, lg: 300, xl: 300 },
                                    width: {xs: "calc(50vw)", sm: "calc(50vw)",  md: 200, lg: 200, xl: 200 },
                                    maxHeight: 300,
                                    maxWidth: 200,
                                    background: COLORS.skeleton, 
                                }} /> }
                        </Box>
                    </Link>
                    <Box sx={{display: "inline-flex", flexDirection: "column", justifyContent: "center"}}>
                        <Box>
                            <Link to={`/comics/${comics.id}`} style={{ textDecoration: 'none', color: "white"}}>
                                <Box>
                                    { comics.isNewRelease && 
                                        <Box>
                                            <NewReleaseBlock/>
                                        </Box>
                                    } 
                                    <Typography sx={{fontFamily: FONTS_FAMILY.header, textTransform: "uppercase", lineHeight: 1.3}}
                                        fontSize={{xs: 18, sm: 18,  md: 24, lg: 24, xl: 24}}
                                    >{comics.name.localized(lang)}</Typography>
                                </Box>
                                { comics.pageCount > 0 && 
                                    <Box sx={{display: 'inline-flex'}}>
                                        <Typography fontSize={{xs: 12, sm: 12,  md: 14, lg: 14, xl: 14}}>{comics.pageCount} {t('pages')}</Typography>
                                    </Box>}
                                { comics.tags && <TagsComponent comics={comics}/> }
                                { comics.announceDate && 
                                    <Box>
                                        <AnnounceDateBlock comics={comics}/>
                                    </Box>
                                } 
                            </Link>
                        </Box>
                        <Box>
                            { comics.quickNote && 
                                <Button target="_blank" href={comics.paperVersion} sx={{marginTop: 0.5, 
                                paddingLeft: 0.5, paddingRight: 0.5, 
                                zIndex: 1000,
                                textAlign: "center", bgcolor: COLORS.red, 
                                borderRadius: 1, textTransform: 'none', color: "white"}}>
                                    <Typography fontSize={{xs: 12, sm: 12,  md: 14, lg: 14, xl: 14}}>{comics.quickNote.localized(lang)}</Typography>
                                </Button>                             
                            }
                        </Box>
                    </Box>
                </Box>

                <Grid container spacing={5} sx={{direction: "row", justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={6}>
                    
                    </Grid>
                    <Grid item xs={6}>
                    
                    </Grid>
                </Grid>
            </Box>
    </Box>
        
    );
}

export default ComicsCover;