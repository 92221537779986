import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from '../utils/firebase';
import { Link } from 'react-router-dom';
import { Box, Skeleton, Typography } from '@mui/material';
import { COLORS, FONTS_FAMILY } from '../values/values';
import { useTranslation } from 'react-i18next';

const WallpaperCollectionCover = (props) => {
    const collection = props.collection
    const { i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const [cover, setCover] = useState("");

    useEffect(() => {
        getDownloadURL(ref(storage, collection.cover)).then((url) => {
            setCover(url)
        }).catch((error) => {
            // Handle any errors
        })
    }, []);

    return (
        <Box sx={{
            alignItems: 'baseline',
            display: "inline-flex",
            paddingBottom: 1,
          }}>
            <Link to={`/gallery/${collection.id}`} style={{ textDecoration: 'none', color: "white"}}>
                { cover ? <Box
                component="img"
                sx={{
                    objectFit: "cover",
                    height: {xs: "calc(50vw - 28px)", sm: "calc(50vw - 28px)", md: 200, lg: 200, xl: 200 },
                    width: {xs: "calc(50vw - 28px)", sm: "calc(50vw - 28px)", md: 200, lg: 200, xl: 200 },
                    maxHeight: 200,
                    maxWidth: 200
                }}
                alt={collection.name.localized(lang)}
                src={cover}
                /> : <Skeleton variant="rectangular" sx={{
                    background: COLORS.skeleton,
                    height: {xs: "calc(50vw - 28px)", sm: "calc(50vw - 28px)", md: 200, lg: 200, xl: 200 },
                    width: {xs: "calc(50vw - 28px)", sm: "calc(50vw - 28px)", md: 200, lg: 200, xl: 200 },
                    maxHeight: 200,
                    maxWidth: 200
                }} /> }
                <Typography sx={{fontFamily: FONTS_FAMILY.header, textTransform: "uppercase"}} fontSize={{xs: 14, sm: 14, md: 17}}>{collection.name.localized(lang) + " (" + collection.items.length + ")"}</Typography>
            </Link>
        </Box>
    );
}

export default WallpaperCollectionCover;