import { Box } from "@mui/system";
import { fetchImageAsync } from "../fetch/imageFetch";
import ComicsLocked from "../BuyComics/ComicsLocked";
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { COLORS } from "../values/values";
import Loader from "../tools/Loader";
import PrismaZoom from 'react-prismazoom'
import { useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const ReaderPage = (props) => {
    const {t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const comics = props.comics
    const topBarHidden = props.topBarHidden
    const [images, setImages] = useState([{status: "waiting"}])
    const [currentPage, setCurrentPage] = useState(props.page)
    const [initialLoading, setInitialLoading] = useState(true)
    const [showControls, setShowControls] = useState(false)
    
    const navigate = useNavigate();
    let prismaRef = useRef()
    const timerRef = useRef(null)
    const hoverRef = useRef(null)
    const paginate = (newDirection) => {
        let newPage = currentPage + newDirection
        if (newPage >= 0 && newPage < images.length) {
            setCurrentPage(newPage)
            props.updateCounter(newPage)
            props.hideSettings(true)
        }
    };

    const handleMouseMove = (e) => {
        if (Math.abs(e.movementX) > 2 || Math.abs(e.movementY) > 2) {
            setShowControls(true)
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            timerRef.current = setTimeout(() => {
                if (hoverRef.current == null) {
                    setShowControls(false)
                }
            }, 1000);
        }
    };

    useEffect(() => {
        if (initialLoading) {
            return
        }
        let backIndex = currentPage - 2
        let futureIndex = currentPage + 2
        if (backIndex >= 0 && backIndex < images.length && images[backIndex].status === "waiting") {
            let page = comics.pages[backIndex]
            loadImage(page)
        }
        if (futureIndex < images.length && images[futureIndex].status === "waiting") {
            let page = comics.pages[futureIndex]
            loadImage(page)
        }
    }, [currentPage])

    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Enter' || 
              event.key === ' ' || 
              event.key === 'ArrowRight') {
            event.preventDefault();
            paginate(1)
          } else if (event.key === 'ArrowLeft') {
            event.preventDefault();
            paginate(-1)
          }
        };
    
        window.addEventListener('keydown', keyDownHandler);
    
        return () => {
          window.removeEventListener('keydown', keyDownHandler);
        };
      }, [paginate]);

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            paginate(1)
        },
        onSwipedRight: (eventData) => {
            paginate(-1)
        },
        onSwipedUp: (eventData) => {
            props.toggleTopBar()
        },
        onSwipedDown: (eventData) => {
            props.toggleTopBar()
        },
    });

    useEffect(() => {
        setInitialLoading(true)
        if (images.length === totalPages()) {
            preloadPages(images)
        } else {
            let arr = initializePages()
            preloadPages(arr)
        }
    }, [props.isMyComics, props.page])

    useEffect(() => {
        if (images.length > 0) {
            setInitialLoading(false)
        }
    }, [images])

    function totalPages() {
        let length = comics.pages.length
        if (!props.isMyComics) {
            length = comics.freePages
        }
        return length
    }
    

    function initializePages() {
        let length = totalPages()
        let imgArr = []
        for(let i = 0; i < length; i++) {
            let image = {status: "waiting"}
            if (i === length - 1 && !props.isMyComics) {
                image = {status: "locked"}
            }
            imgArr.push(image)
        }
        return imgArr
    }

    function preloadPages(imgArr) {
        let initialPage = props.page
        let length = imgArr.length
        let range = {start: 0, end: 0}
        if (initialPage === 0) {
            range = {start: 0, end: 2}
        } else if (initialPage === 1) {
            range = {start: 0, end: 3}
        } else if (initialPage >= length - 1) {
            range = {start: length - 3, end: length - 1}
        } else if (initialPage === length - 2) { 
            range = {start: length - 4, end: length - 1}
        } else {
            range = {start: initialPage - 2, end: initialPage + 2}
        }

        function checkImages() {
            let loaded = true
            imgArr.forEach((img) => {
                if (img.status === "loading") {
                    loaded = false
                }
            })
            if (loaded) {
                setImages(imgArr)
                if (props.page >= imgArr.length) {
                    setCurrentPage(imgArr.length - 1)
                    props.updateCounter(imgArr.length - 1)
                } else {
                    setCurrentPage(props.page)
                }
            }
        }

        for (let i = range.start; i <= range.end; i++) {
            let page = comics.pages[i]
            if (imgArr[i].status === "waiting") {
                let url = fetchImageAsync(page.original(lang))
                imgArr[i].status = "loading"
                url.then(function (val) {
                if (val !== undefined) {
                    imgArr[i] = {image: val, status: "loaded"}
                    checkImages()
                } else {
                    imgArr[i] = {status: "error"}
                    checkImages()
                }
            })
            } else {
                let loaded = true
                checkImages()
            }
        }
    }

    function updateImages(object, index) {
        const nextImages = images.map((c, i) => {
            if (i === index) {
              return object
            } else {
              return c
            }
          });
        return nextImages
    }

    const onFailedImage = (index) => {
        let newImages = updateImages({status: "error"}, index)
        setImages(newImages)
    }

    const loadImage = (page) => {
        if (images[page.number].status === "loaded" || images[page.number].status === "loading") {
            return
        }
        let url = fetchImageAsync(page.original(lang))
        images[page.number].status = "loading"
        url.then(function (val) {
            if (val !== undefined) {
                let newImages = updateImages({image: val, status: "loaded"}, page.number)
                setImages(newImages)
            } else {
                let newImages = updateImages({status: "error"}, page.number)
                setImages(newImages)
            }
        })
    }

    const onPageClick = (e) => {
        if (e.target.tagName !== "BUTTON") {
            props.toggleTopBar()
        }
    }

    const onReload = (index) => {
        let page = comics.pages[index]
        loadImage(page)
    }

    function ControlBlocks() {
        return <Box
            sx={{
                opacity: showControls ? 1 : 0,
                transition: "0.2s ease-in-out"
            }}>
            { topBarHidden && <Button
                onClick={ () => {navigate("/comics/" + comics.id, {replace: true})}}
                onMouseOver={() => {hoverRef.current = true}}
                onMouseOut={() => {hoverRef.current = null}}
                sx={{
                    background: "black",
                    opacity: 0.5,
                    left: "10px",
                    position: "absolute",
                    top: "10px",
                    zIndex: 1000,
                    width: "30px",
                    minHeight: 0,
                    minWidth: 0,
                    padding: 0,
                    height: "30px",
                    borderRadius: "15px",
                    color: "white",
                    '&:hover': {
                        backgroundColor: COLORS.button_hover,
                        color: "white",
                    }
                }}>
                <FontAwesomeIcon icon={["fas", "xmark"]} />
            </Button>  }
            { currentPage < images.length - 1 && <Button
                onClick={() => {paginate(1)}}
                onMouseOver={() => {hoverRef.current = true}}
                onMouseOut={() => {hoverRef.current = null}}
                sx={{
                    background: COLORS.blue,
                    opacity: 0.8,
                    right: "10px",
                    position: "absolute",
                    top: "calc(50% - 15px)",
                    zIndex: 1000,
                    width: "30px",
                    minHeight: 0,
                    minWidth: 0,
                    padding: 0,
                    height: "30px",
                    borderRadius: "15px",
                    color: "white",
                    '&:hover': {
                        backgroundColor: COLORS.button_hover,
                        color: "white",
                    }
                }}>
                <FontAwesomeIcon icon={["fas", "angle-right"]} />
            </Button> }
            { currentPage > 0 && <Button
                onMouseOver={() => {hoverRef.current = true}}
                onMouseOut={() => {hoverRef.current = null}}
                onClick={() => {paginate(-1)}}
                sx={{
                    background: COLORS.blue,
                    opacity: 0.8,
                    left: "10px",
                    position: "absolute",
                    top: "calc(50% - 15px)",
                    zIndex: 1000,
                    width: "30px",
                    minHeight: 0,
                    minWidth: 0,
                    padding: 0,
                    height: "30px",
                    borderRadius: "15px",
                    color: "white",
                    '&:hover': {
                        backgroundColor: COLORS.button_hover,
                        color: "white",
                    }
                }}>
                <FontAwesomeIcon icon={["fas", "angle-left"]} />
            </Button> }
        </Box>
    }

    return <Box 
                sx={{lineHeight: 1,
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "clear"}}>
            <ControlBlocks/>
            <div onMouseMove={handleMouseMove}>
                <Box 
                    {...handlers}
                    sx={{display: "inline-flex",
                        overflowY: "scroll",
                        overflow: 'hidden',
                        flexDirection: "row",
                        transition: "0.2s ease-in-out",
                        transform: `translateX(-${currentPage * 100}vw)`}}>
                    {
                        images.map( (image, index) => (
                            <Box key={index} 
                                onClick={onPageClick}
                                sx={{
                                display: "flex",
                                justifyContent: "center", 
                                alignItems: "center",
                                overflow: "hidden",
                                minWidth: "100vw",
                                height: window.visualViewport.height}}>
                                {image.status === "loading" || image.status === "waiting" && 
                                    <Loader/>
                                }
                                {image.status === "loaded" && 
                                    <PrismaZoom
                                        doubleTouchMaxDelay={0}
                                        allowTouchEvents={true}
                                        ref={prismaRef}
                                        style={{paddingBottom: "0px"}}>
                                        <img style={{maxWidth: "100vw", maxHeight: "100vh"}} src={image.image} onError={() => {
                                            onFailedImage(index)
                                        }}/>
                                    </PrismaZoom>
                                }
                                {image.status === "locked" && 
                                    <Box sx={{position: "relative", justifyContent: "center", display: "flex", alignItems: "center"}}>
                                    <Box sx={{width: "400px", position: "absolute", zIndex: 1000}}>
                                        <ComicsLocked 
                                        userData={props.userData}
                                        currentUser={props.currentUser}
                                        comics={comics} 
                                        darkMode={true}/>
                                    </Box>
                                    <Box
                                        component="img"
                                        sx={{
                                            objectFit: "cover",
                                            height: "100vh",
                                            filter: "blur(14px) brightness(50%)"
                                        }}
                                        alt={props.page}
                                        src={comics.thumbnail}/>
                                </Box>
                                }
                                {image.status === "error" && 
                                    <Box sx={{textAlign: "center"}}>
                                    <Typography sx={{color: "white", paddingBottom: 2}}>{t("invalid_error")}</Typography>
                                    <Button onClick={() => {onReload(index)}} variant="contained" sx={{color: "white", 
                                    fontWeigth: "bold", background: COLORS.blue,
                                    zIndex: 10000,
                                    '&:hover': {
                                        backgroundColor: COLORS.button_hover,
                                        color: "white",
                                    }}}>{t("reload")}</Button>
                                </Box>}
                            </Box>
                        ))
                    }
                </Box>
            </div>
            
    </Box>
}
 
export default ReaderPage;