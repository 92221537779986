
import { AppBar, Avatar, Button, Container, Divider, Grid, IconButton, Menu, MenuItem, Modal, Toolbar, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import LoginContrainer from '../login/LoginContainer';
import SelectLanguage from './SelectLanguage';
import { analytics, auth } from '../utils/firebase';
import { signOut } from "firebase/auth";
import { modalStyle } from '../utils/customStyles';
import logo_text from '../images/logo_text.png'
import React, {useCallback} from 'react';
import { COLORS } from '../values/values';
import {Link, useNavigate} from 'react-router-dom';
import AvatarSelect from '../account/AvatarSelect';
import { useTranslation } from 'react-i18next'
import { useImperativeHandle } from 'react';
import EditAccount from '../account/EditAccount';
import SupportPage from '../account/Support';
import { logEvent } from 'firebase/analytics';
import { setRandomAvatar } from '../fetch/userDataFetch';

const Navbar = ({ innerRef }) => {
    const [userValid, setUserValid] = useState(false)
    const { t } = useTranslation()
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setUserValid(user.emailVerified)
            } else {
                setUserValid(false)
            }
            if (user != null) {
                if (user.emailVerified == true) {
                    closeLogin()
                }
            }
        })
    }, [])
    const [selectAvatar, setSelectAvatar] = useState(false)
    const [editAccount, setEditAccount] = useState(false)
    const [login, setLogin] = useState(false)
    const [support, setSupport] = useState(false)
    const closeSupport = () => setSupport(false)
    const closeLogin = () => setLogin(false)
    const openLogin = () => setLogin(true)
    const openSupport = () => {
        handleCloseUserMenu()
        setSupport(true)
    }
    const openSelectAvatar = () => { 
        handleCloseUserMenu()
        setSelectAvatar(true) 
    }

    const openEditAccount = () => { 
        handleCloseUserMenu()
        setEditAccount(true) 
    }
    
    useImperativeHandle(innerRef, () => ({
        doEditAccount: () => {
            openEditAccount()
        },
        doOpenSupport: () => {
            openSupport()
        },
        doSelectAvatar: () => {
            openSelectAvatar()
        },
        doOpenLogin: () => {
            openLogin()
        }
    }));

    const closeSelectAvatar = () => setSelectAvatar(false)
    const closeEditAccount = () => setEditAccount(false)
    const logoutUser = () => {
        signOut(auth).then(() => {
            handleCloseUserMenu()
        }).catch((error) => {
            // An error happened.
        });
    }
    const navigate = useNavigate();
    const openHomeComics = useCallback(() => navigate('/#comics', {replace: true}), [navigate]);
    const openHomeWallpapers = useCallback(() => navigate('/#wallpapers', {replace: true}), [navigate]);
    const openHomeStickers = useCallback(() => navigate('/#stickers', {replace: true}), [navigate]);
    const openNews = () => {
        window.open("https://news.zhewr.net.ua", "_blank", "noreferrer")
    }

    const openAccount = useCallback(() => {
        navigate('/myaccount', {replace: true})
        handleCloseUserMenu()
    }, [navigate]);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
      };
    
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return ( 
        <Container maxWidth="md" ref={innerRef}>
        <AppBar position="static" elevation={0} sx={{bgcolor: COLORS.gray }}>
                <Box sx={{display: "inline-flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <Button sx={{height: "50px"}} onClick={() => {navigate("/", {replace: true})}}>
                            <Box
                                component="img"
                                sx={{
                                    objectFit: "cover",
                                    maxHeight: "36px",
                                    marginTop: "-4px"
                                }}
                                alt="ЖЕВР"
                                src={logo_text}
                                />
                    </Button>
                    <Box display={{xs: "none", sm: "block"}}>
                        <Box sx={{display: "flex", justifyContent: "center", height: "50px"}}>
                            <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={() => {
                                logEvent(analytics, 'show_comics')
                                openHomeComics()
                            }}>
                                {t('comics_header')}
                            </Button>
                            <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={() => {
                                logEvent(analytics, 'show_wallpapers')
                                openHomeWallpapers()
                            }}>
                                {t('wallpapers_header')}
                            </Button>
                            <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={() => {
                                logEvent(analytics, 'show_stickers')
                                openHomeStickers()
                            }}>
                                {t('stickers_header')}
                            </Button>
                            <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={() => {
                                openNews()
                            }}>
                                {t('news')}
                            </Button>
                        </Box>
                    </Box>
                    <Box>
                    <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                            <Box sx={{display: "flex", justifyContent: "center", height: "50px"}}>
                            { userValid ? <Box sx={{display: "flex", flexDirection: "row"}}>
                                <Button sx={{fontSize: 11, color: 'white', display: 'block' }} onClick={openAccount}>
                                    {t('my_library')}
                                    </Button>
                                <Tooltip title={t('open_settings')}>
                                <IconButton onClick={handleOpenUserMenu}>
                                    <Avatar alt={auth.currentUser.displayName} src={auth.currentUser.photoURL} />
                                </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                    >
                                    <MenuItem key="edit" onClick={openEditAccount}>
                                        <Box sx={{ display: 'flex', flexDirection: "column"}}>
                                            <Typography sx={{color: COLORS.blue, fontWeight: "bold"}}>{auth.currentUser.displayName}</Typography>
                                            <Typography sx={{fontSize: 12}}>{auth.currentUser.email}</Typography>
                                            <Typography sx={{fontSize: 12, fontWeight: "bold"}}>{t('edit_profile')}</Typography>
                                        </Box>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem key="library" onClick={openAccount}>
                                        <Typography textAlign="center">{t('my_library')}</Typography>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem key="avatar" onClick={openSelectAvatar}>
                                        <Typography textAlign="center">{t('change_avatar')}</Typography>
                                    </MenuItem>
                                    <MenuItem key="support" onClick={openSupport}>
                                        <Typography textAlign="center">{t('support')}</Typography>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem key="logout" onClick={logoutUser}>
                                        <Typography textAlign="center">{t('signout')}</Typography>
                                    </MenuItem>
                                    </Menu>
                                </Box> : <Box sx={{width: "106px"}}></Box>}
                                { (auth.currentUser == null || !userValid) && <Button sx={{color: 'white', 
                                             backgroundColor: COLORS.blue, 
                                             marginTop: 1, 
                                             height: "30px",
                                             "&:hover": {
                                                background: COLORS.button_hover,
                                            }}} onClick={openLogin}>{t('signin')}</Button>}
                                <Box sx={{display: "none", paddingTop: "5px"}}>
                                    <SelectLanguage/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{width: "100%", 
                          textAlign: "center",
                          background: "orange"}}>
                    <Typography sx={{fontSize: 13, color: "black", fontWeight: "bold"}}>
                        {t('beta_test')}
                    </Typography>
                </Box>
            <Modal
            open={selectAvatar}
            onClose={closeSelectAvatar}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
                <Box sx={modalStyle} backgroundColor={"white"}>
                    <AvatarSelect onClose={closeSelectAvatar} />
                </Box>
            </Modal>
            <Modal
            open={editAccount}
            onClose={closeEditAccount}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
                <Box sx={modalStyle} backgroundColor={"white"}>
                    <EditAccount onClose={closeEditAccount} />
                </Box>
            </Modal>
            <Modal
                open={login}
                onClose={closeLogin}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle} backgroundColor={"white"}>
                    <LoginContrainer onClose={closeLogin} />
                </Box>
            </Modal>
            <Modal
                open={support}
                onClose={closeSupport}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle} backgroundColor={"white"}>
                    <SupportPage onClose={closeSupport} />
                </Box>
            </Modal>
        </AppBar>
        </Container>
     );
}
 
export default Navbar;