import { Box, Button, Skeleton, Typography } from "@mui/material";
import { COLORS } from "../values/values";
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from "react";
import OperationError from "./OperationError";
import AcceptingAggreements from "../login/AcceptingAggreements";
import Loader from "../tools/Loader";
import { generateLiqPayScript } from "../utils/liqpayFunctions";

const ConfirmBuyComics = (props) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const comics = props.comics
    const image = comics.thumbnail
    const [operationError, setOperationError] = useState(false)
    const [priceStr, setPriceStr] = useState("")

    const [status, setStatus] = useState("loading")

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "//static.liqpay.ua/libjs/checkout.js";
        script.async = true;
        script.onload = () => {
            buyComics()
        }
        document.body.appendChild(script);
        
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    useEffect(() => {
        if (comics.price && comics.price.amount && comics.price.currency) {
            setPriceStr(comics.price.amount + " " + t(comics.price.currency))
        }
    }, []);

    const buyComics = async() => {
        if (comics && comics.id) {
            setStatus("loading")
            setOperationError(false)
            generateLiqPayScript(comics).then(function(val) {
                let liqpay = window["LiqPayCheckout"].init({
                    data: val.data,
                    signature: val.signature,
                    embedTo: "#liqpay_checkout",
                    language: "uk",
                    mode: "popup" // embed || popup
                }).on("liqpay.callback", function(data){
                    if (data.status === "success") {
                        
                    } else {
                        setStatus("readyToBuy")
                        setOperationError(true)
                    }
                }).on("liqpay.ready", function(data){
                    // ready
                }).on("liqpay.close", function(data){
                    setStatus("readyToBuy")
                });
            })    
        }
    }

    function BuyBlock() {
        return (<Box sx={{minWidth: "300px"}}>
            <Button onClick={buyComics} variant="contained" sx={{
                        width: "100%", color: "white", 
                        marginBottom: 1,
                        marginTop: 2,
                        fontWeigth: "bold", background: COLORS.blue,
                        '&:hover': {
                            backgroundColor: COLORS.button_hover,
                            color: "white",
                        }}}>
                            {t("buy_online_version")}
            </Button>
        </Box>)
    }
    
    return ( <Box>
        { image ? <Box
            component="img"
            sx={{
                objectFit: "cover",
                height: 150,
                width: 100
            }}
            alt={comics.name.localized(lang)}
            src={image}
            /> : <Skeleton variant="rectangular" sx={{
                height: 150,
                width: 100,
                background: COLORS.skeleton, 
            }} /> }
        <Typography sx={{fontSize: 14, color: props.darkMode ? "white" : "black",}}>{comics.name.localized(lang)}</Typography>
        <Typography sx={{fontSize: 18, fontWeight: "bold", paddingBottom: 1, color: props.darkMode ? "white" : "black",}}>{t('price')  + ": " + priceStr}</Typography>
        { status === "readyToBuy" && <BuyBlock/> }
        { status === "loading" && <Loader color={COLORS.blue}/> }
        {operationError && <OperationError/>}
        <AcceptingAggreements darkMode={props.darkMode} buyMode={true}/>
    </Box> );
}
 
export default ConfirmBuyComics;