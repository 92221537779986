import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../utils/firebase';
import { useParams } from "react-router-dom";
import Comics from "../models/Comics";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { Button, Modal } from '@mui/material';
import { modalStyle } from '../utils/customStyles';
import { Box } from '@mui/system';
import ReaderPage from './ReaderPage';
import ReaderSettings from './ReaderSettings';
import ReaderWebtoon from './ReaderWebtoon';
import { COLORS } from '../values/values';
import { checkUserData, createUserData, fetchUserData, updateComicsProgress } from '../fetch/userDataFetch';
import PageLoader from '../tools/PageLoader';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReaderTutorial from './ReaderTutorial';
import { useCookies } from "react-cookie";
import UserData from '../models/UserData';

const Reader = () => {
    const { i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    const { id, number } = useParams();
    const [comics, setComics] = useState(null);
    const [switchPage, setSwitchPage] = useState(0);
    const [counterPage, setCounterPage] = useState(0);
    const [mode, setMode] = useState("none");
    const [thumbnails, setThumbnails] = useState([])
    const [isMyComics, setIsMyComics] = useState(false)
    const [interactiveUrl, setInteractiveUrl] = useState(null)
    const [showTutorial, setShowTutorial] = useState(false)
    const [cookies, setCookie] = useCookies(['showTutorial']);
    const [currentUser, setCurrentUser] = useState(null)

    const onUpdateThumbnails = (thumbs) => {
        setThumbnails(thumbs)
    }

    const setClassic = () => {
        setMode("classic")
    };

    const setWebtoon = () => {
        setMode("webtoon")
    }

    const nextPage = () => {
        let page = counterPage < totalPages() - 1 ? counterPage + 1 : counterPage
        setSwitchPage(page);
        setCounterPage(page);
        updateComicsProgress(comics.id, page)
    }

    const previousPage = () => {
        let page = counterPage > 0 ? counterPage - 1 : counterPage
        setSwitchPage(page)
        setCounterPage(page)
    }

    const onPageSelect = (index) => {
        let newIndex = index
        if (index >= totalPages() - 1) {
            newIndex = totalPages() - 1
        }
        setSwitchPage(newIndex)
        setCounterPage(newIndex)
        updateComicsProgress(comics.id, newIndex)
    }

    const onCounterUpdate = (index) => {
        setCounterPage(index)
    }

    const hideSettings = (hide) => {
        setHideTopBar(hide)
    }

    const toggleTopBar = () => {
        setHideTopBar(!hideTopBar)
    }

    const [hideTopBar, setHideTopBar] = useState(true)

    const fetchComics = async () => {
        const docRef = doc(db, "comics", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const c = new Comics(docSnap.id, docSnap.data())
            setComics(c)
            let show = cookies['showTutorial']
            setShowTutorial(show == undefined)
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        if (comics && comics.interactive && comics.interactive[counterPage]) {
            setInteractiveUrl(comics.interactive[counterPage].url)
        } else {
            setInteractiveUrl(null)
        }
    }, [counterPage])

    const [userData, setUserData] = useState(undefined)
    const unsubscribeRef = useRef()
    const [checkedUserData, setCheckedUserData] = useState(false)
    
    useEffect(() => {
        subscribeToUserDataChanges()
        auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            if (user) {
                if (userData === null) {
                    subscribeToUserDataChanges()
                }
            } else {
                setUserData(null)
            }
        })
        return () => {
            if (unsubscribeRef.current) {
                unsubscribeRef.current()
                unsubscribeRef.current = undefined
            }
        }
    }, [comics]);

    const subscribeToUserDataChanges = () => {
        if (currentUser != null && currentUser.emailVerified) {
            const docRef = doc(db, "userData", currentUser.uid);
            if (unsubscribeRef.current === undefined) {
                unsubscribeRef.current = onSnapshot(docRef, (doc) => {
                    if (doc.exists()) {
                        const c = new UserData(currentUser.uid, doc.data())
                        checkUserData(c)
                        setUserData(c)
                    } else {
                        createUserData(docRef)
                    }
                });
            }
        }
    }

    useEffect(() => {
        async function fetchData() {
            setSwitchPage(parseInt(number))
            setCounterPage(parseInt(number))
            setIsMyComics(isMyComics)
            fetchComics()
            updateComicsProgress(id, parseInt(number))
            if (window.screen.height > window.screen.width) {
                setWebtoon()
            } else {
                setClassic()
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (userData) {
            setIsMyComics(userData.isMyComics(id))
            if (number === 0) {
                setSwitchPage(userData.progressForComics(id))
                console.log(userData.progressForComics(id))
                setCounterPage(userData.progressForComics(id))
            }
        }
        
        if (userData !== undefined) {
            setCheckedUserData(true)
        }
        
    }, [userData])

    function totalPages() {
        let length = comics.pages.length
        if (!isMyComics) {
            length = comics.freePages
        }
        return length
    }

    function InteractiveBlock() {
        return <Button
            target="_blank"
            href={interactiveUrl}
            sx={{
                transition: "0.2s ease-in-out",
                background: "#ffd300",
                opacity: interactiveUrl ? 0.8 : 0,
                left: "10px",
                position: "absolute",
                bottom: "60px",
                zIndex: 1000,
                width: "30px",
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                height: "30px",
                borderRadius: "10px",
                color: "black",
                '&:hover': {
                    backgroundColor: COLORS.button_hover,
                    color: "white",
                }
            }}>
            <FontAwesomeIcon icon={["fas", "music"]} />
        </Button> 
    }

    return (
        <Box sx={{ background: COLORS.gray, height: "100vh", overflow: 'hidden'}}>
            {comics &&
                <Box>
                    <Helmet>
                        <title>{comics.name.localized(lang)}</title>
                        <meta property="og:title" content={comics.name.localized(lang)}/>
                        <meta property="og:image" content={comics.cover}/>
                        <meta property="og:description" content={comics.synopsis.localized(lang)}/>
                    </Helmet>
                    <InteractiveBlock/>
                    <Box sx={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center", alignItems: "center" }}>
                    {mode === "classic" && checkedUserData &&
                        <Box>
                            <ReaderPage 
                            page={counterPage} 
                            comics={comics} 
                            isMyComics={isMyComics}
                            updateCounter={onCounterUpdate}
                            onNextPage={nextPage}
                            onPreviousPage={previousPage}
                            topBarHidden={hideTopBar}
                            toggleTopBar={toggleTopBar}
                            hideSettings={hideSettings}
                            currentUser={currentUser}
                            userData={userData}/>
                        </Box> }
                    { mode === "webtoon" && checkedUserData && <Box>
                        <ReaderWebtoon 
                        comics={comics} 
                        currentPage={switchPage} 
                        isMyComics={isMyComics}
                        onCounterUpdate={onCounterUpdate}
                        hideSettings={hideSettings}
                        showSettings={toggleTopBar}
                        currentUser={currentUser}
                        userData={userData}/>
                    </Box> }
                    { mode === "none" && 
                        <PageLoader/>
                    }
                </Box>
                </Box>
            }
            {comics && 
                <Box sx={{
                    opacity: hideTopBar ? "0" : "1",
                    transition: "all .3s",
                    visibility: hideTopBar ? "hidden" : "visible"
                    }}>
                    <ReaderSettings
                        setClassic={setClassic}
                        setWebtoon={setWebtoon}
                        comics={comics}
                        currentPage={counterPage}
                        onNextPage={nextPage}
                        onPreviousPage={previousPage}
                        mode={mode}
                        onPageSelect={onPageSelect}
                        thumbnails={thumbnails}
                        onUpdateThumbnails={onUpdateThumbnails}
                        hideSettings={hideSettings}
                        isMyComics={isMyComics}
                        userData={userData}
                        currentUser={currentUser}
                        showTutorial={() => {setShowTutorial(true)}}
                    />
                </Box>}
                
                <Modal
                    open={showTutorial}
                    onClose={() => {
                        setShowTutorial(false)
                        setCookie('showTutorial', false, { path: '/' });
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                        <Box sx={modalStyle} backgroundColor={COLORS.blue}>
                            <ReaderTutorial onClose={() => {
                                setShowTutorial(false)
                                setCookie('showTutorial', false, { path: '/' });
                            }}/>
                        </Box>
                    </Modal>
        </Box>);
}

export default Reader;