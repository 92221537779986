import CollectionItem from './CollectionItem.js'
import TranslatableText from './TranslatableText.js'

export default class WallpaperCollection {
  constructor(id, data) {
    this.id = id
    this.folder = data.folder
    this.name = new TranslatableText(data.name)
    this.items = []
    if (data.items !== null && data.items.length > 0) {
      let items = []
      data.items.forEach(element => {
        let r = new CollectionItem(element, this.folder)
        items.push(r)
      })
      this.items = items
    }
  }

  get cover() {
    return 'wallpapers/' + this.folder + '/cover.jpg'
  }
}