import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Copyright from './Copyright';
import logo from '../images/logo.png'
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { COLORS } from '../values/values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { useState } from 'react';

export default function ForgotPassword(props) {
  const { t } = useTranslation()

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null)
    setSuccess(null)
    const data = new FormData(event.currentTarget);
    if (data.get('email')) {
      sendPasswordResetEmail(auth, data.get('email'))
      .then(() => {
        setSuccess(t('reset_instructions'))
      })
      .catch((error) => {
        setError(error.message)
      });
    }
  };

  const goSignIn = () => { props.updateMode("signin") }

  return (
    <Box>
      {props.onClose && <Button sx={{ color: "black", minWidth: "44px", fontSize: 18, position: "absolute", right: 20}} onClick={props.onClose}>
          <FontAwesomeIcon icon={["fas", "xmark"]} />
      </Button>}
      
      <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
                component="img"
                sx={{
                    objectFit: "cover",
                    height: 50,
                    width: 50,
                    borderRadius: 25
                }}
                alt={t('zhevr')}
                src={logo}
                />
          <Typography sx={{paddingTop: 1, fontWeight: "700"}}>{t('forgot_password')}</Typography>
          <Typography>{t('enter_email_forgot_password')}</Typography>
          <Box width={'80vw'} maxWidth={368} component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              id="email"
              label={t('email')}
              name="email"
              autoComplete="email"
              autoFocus
              fullWidth
            />
            {error && <Typography sx={{color: "red"}}>{error}</Typography>}
            {success && <Typography sx={{color: "green"}}>{success}</Typography>}
    
            <Box sx={{paddingTop: 2, paddingBottom: 2, display: "flex", flexDirection: "column", alignItems: 'center'}}>
              <Button
                type="submit"
                variant="contained"
                sx={{bgcolor: COLORS.blue}}
              >
                {t('send')}
              </Button>
            </Box>
            <Grid container>
              <Grid item xs>
                <Button onClick={goSignIn}>
                  <Typography sx={{color: COLORS.blue, fontSize: 12, fontWeight: 600}}>
                    {t('remember_password_question')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
    </Box>
  );
}