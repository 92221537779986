import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { auth } from "../utils/firebase";
import ForgotPassword from "./ForgotPassword";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import VerifyEmail from "./VerifyEmail";

const LoginContrainer = (props) => {
    const [mode, setMode] = useState("none")
    
    useEffect(() => {
        if (auth.currentUser) {
            if (auth.currentUser.emailVerified === false) {
                setMode("verify")
            }
        } else {
            setMode("signin")
        }
    }, []);

    const updateMode = (name) => {
        setMode(name)
    }

    function LoginMode() {
        if (mode === "signin") {
            return <LoginForm updateMode={updateMode} info={props.info} onClose={props.onClose}/>
        } else if (mode === "signup") {
            return <SignUpForm updateMode={updateMode} onClose={props.onClose}/>
        } else if (mode === "forgotpassword") {
            return <ForgotPassword updateMode={updateMode} onClose={props.onClose}/>
        } else if (mode === "verifySend") {
            return <VerifyEmail updateMode={updateMode} send={true} onClose={props.onClose}/>
        } else if (mode === "verify") {
            return <VerifyEmail updateMode={updateMode} send={false} onClose={props.onClose}/>
        }
        return null
      }

    return ( <Box sx={{background: "white", padding: 2, borderRadius: 2}}>
        <LoginMode/>
    </Box> );
}
 
export default LoginContrainer;