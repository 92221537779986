import useImageFetch from "../fetch/imageFetch";
import { Link } from 'react-router-dom';
import { Box, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next"
import { COLORS } from "../values/values";

export function CreatorListSkeleton() {
    function Placeholder() {
        return <Box sx={{display: "inline-flex", flexDirection: "column", paddingRight: 1}}>
                    <Skeleton variant="rectangular" sx={{
                            height: 40,
                            width: 40,
                            borderRadius: 20,
                            background: COLORS.skeleton,
                            display: "inline",
                            marginBottom: 1,
                    }} />
                    <Skeleton variant="rectangular" sx={{
                            height: 15,
                            width: 40,
                            borderRadius: 2,
                            background: COLORS.skeleton,
                            display: "inline",
                            marginBottom: 1,
                    }} />
                    <Skeleton variant="rectangular" sx={{
                            height: 15,
                            width: 40,
                            borderRadius: 2,
                            background: COLORS.skeleton,
                            display: "inline"
                    }} />
                </Box>
    }

    return <Box sx={{display: "inline-flex", paddingBottom: 1}}>
            <Placeholder/>
            <Placeholder/>
        </Box>
}

const CreatorsList = (props) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage

    let creators = props.creators
    let roles = props.roles
    function RoleTitle(props) {
        const creator = props.creator 
        const roles = props.roles
        let r = []
        let role = roles.find(element => element.id === creator.id)
        role.roles.forEach( element => {
            if (element === "author") {
                r.push(t('role_author'))
            } else if (element === "scenario") {
                r.push(t('role_scenario'))
            } else if (element === "ink") {
                r.push(t('role_ink'))
            } else if (element === "art") {
                r.push(t('role_art'))
            } else if (element === "color") {
                r.push(t('role_color'))
            } else if (element === "director") {
                r.push(t('role_director'))
            } else if (element === "layout") {
                r.push(t('role_layout'))
            } else if (element === "translation") {
                r.push(t('role_translation'))
            } else if (element === "studio") {
                r.push(t('role_studio'))
            } else if (element === "publisher") {
                r.push(t('role_publisher'))
            }
        })
        return <Typography sx={{fontSize: 12, color: "white"}}>{r.join(", ")}</Typography>
    }

    function CreatorBlock(props) {
        const creator = props.creator
        const roles = props.roles
        const { image } = useImageFetch(creator.cover);
        return <Box sx={{textAlign: "center", display: "flex-inline", paddingLeft: 1, paddingRight: 1}}>{ image ? <Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        height: 40,
                        width: 40,
                        borderRadius: 20,
                        display: "inline-flex"
                    }}
                    alt={creator.name.localized(lang)}
                    src={image}
                    /> : <Skeleton variant="rectangular" sx={{
                        height: 40,
                        width: 40,
                        borderRadius: 20,
                        display: "inline"
                    }} /> }
                    <Typography sx={{fontSize: 16, color: "white"}}>{creator.name.localized(lang)}</Typography>
                    <RoleTitle creator={creator} roles={roles}/> 
               </Box>
    }

    function CreatorsRow(props) {
        const creators = props.creators;
        const roles = props.roles;
        return (<Box sx={{display: "inline-flex"}}>
            { creators.map( element => (
            <Box key={element.id} sx={{display: "inline-flex", paddingBottom: 1}}>
                <Link to={`/creator/${element.type}/${element.id}/`} style={{ textDecoration: 'none', color: "white"}}>
                    <CreatorBlock creator={element} roles={roles}/>
                </Link>
            </Box>)) }
        </Box>)
    }

    return ( <div>
        { creators && <CreatorsRow creators={creators} roles={roles}/>}
    </div> );
}
 
export default CreatorsList;