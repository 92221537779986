import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from 'react-i18next'
import { COLORS } from "../values/values";
import mascot_buy from "../images/mascot_buy.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { analytics } from "../utils/firebase";
import { useRef, useState } from "react";
import LoginContrainer from "../login/LoginContainer";
import ConfirmBuyComics from "./ConfirmBuyComics";
import { useEffect } from "react";
import Loader from "../tools/Loader";
import BuySuccessComics from "./BuySuccessComics";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { logEvent } from "firebase/analytics";
import { GENERAL_CONFIG } from "../utils/generalConfig";

const ComicsLocked = (props) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.resolvedLanguage
    let comics = props.comics
    let directBuy = props.directBuy
    const [mode, setMode] = useState("loading")
    const [userData, setUserData] = useState(props.userData)
    const [currentUser, setCurrentUser] = useState(props.currentUser)

    const navigate = useNavigate();
    const openReaderOnPage = useCallback((page) => {
        const path = "/reader/" + comics.id + "/" + page
        navigate(path, {replace: true}) 
    }, [navigate, comics]);

    const readComics = async () => {
        let progress = 0
        if (userData) {
            progress = userData.progressForComics(comics.id)
        }
        openReaderOnPage(progress)
    }

    useEffect(() => {
        setUserData(props.userData)
        if (props.userData) {
            if (props.userData.isMyComics(comics.id)) {
                if (mode === "buy") {
                    setMode("bought")
                } else {
                    if (props.onClose) {
                        props.onClose()
                    }
                }
            } else {
                setMode("buy")
            }
        }
    }, [props.userData])

    const onReadComics = () => {
        if (props.onClose) {
            readComics()
        }
    }

    useEffect(() => {
        setCurrentUser(props.currentUser)
        if (props.directBuy && directBuy === true) {
            if (props.currentUser && props.currentUser.emailVerified === true) {
                updateMode("buy")
            } else {
                updateMode("login")
            }
        } else {
            updateMode("options")
        }
    }, [props.currentUser]);

    const updateMode = (mode) => {
        setMode(mode)
    }

    const goBack = () => {
        updateMode("options")
    }

    const buyComics = () => {
        if (currentUser != null && currentUser.emailVerified === true) {
            updateMode("buy")
        } else {
            updateMode("login")
        }
    }

    function NavigationBlock() {
        if (mode === "loading") {
            return <Box>
                    <CloseButtonBlock/>
                </Box>
        } else {
            return <Box>
                <BackButtonBlock/>
                <CloseButtonBlock/>
            </Box> 
        }  
    }

    function CloseButtonBlock() {
        return <Box>
            {props.onClose && 
                <Button sx={{ color: "black", minWidth: "44px", fontSize: 18, position: "absolute", top: 22, right: 10}} onClick={props.onClose}>
                    <FontAwesomeIcon icon={["fas", "xmark"]} />
                </Button>}
        </Box>
    }

    function BackButtonBlock() {
        if (mode === "options" || props.directBuy === true || mode === "bought") {
            return null
        } else {
            return <Button sx={{ color: props.darkMode && mode !== "login" ? "white" : "black", minWidth: "44px", fontSize: 18, position: "absolute", left: 10, top: 20}} onClick={goBack}>
                <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </Button>
        }
    }

    function LockedBlock() {
        let priceStr = ""
        if (comics.price && comics.price.amount && comics.price.currency) {
            priceStr = comics.price.amount + " " + t(comics.price.currency)
        }
        return (
            <Box sx={{minWidth: "300px"}}>
        <Box
            component="img"
            sx={{
                objectFit: "cover",
                height: 200,
                width: 200,
            }}
            alt={t("zhevr")}
            src={mascot_buy}
            />
        <Typography sx={{paddingBottom: 2, color: props.darkMode ? "white" : "black" }}>{t("to_read_buy")}</Typography>
        <Typography sx={{paddingBottom: 2, fontSize: 24, fontWeight: "bold", color: props.darkMode ? "white" : "black" }}>{comics.name.localized(lang)}</Typography>
        {GENERAL_CONFIG.enableBuyComics && <Button onClick={buyComics} variant="contained"
                startIcon={<FontAwesomeIcon style={{fontSize: 14, color: "white"}} icon={["fas", "shopping-cart"]}/>} 
                    sx={{ width: "100%", color: "white", 
                    marginBottom: 2,
                    fontWeigth: "bold", background: COLORS.blue,
                    '&:hover': {
                        backgroundColor: COLORS.button_hover,
                        color: "white",
                    }}}>
                        {t("buy_online_version") + (priceStr.length > 0 ? (" - " + priceStr) : "")}
                </Button>}
        <Button target="_blank" href={comics.paperVersion} variant="contained" sx={{
                    width: "100%", color: "white", 
                    marginBottom: 2,
                    fontWeigth: "bold", background: "black",
                    '&:hover': {
                        backgroundColor: COLORS.button_hover,
                        color: "white",
                    }}}
                    onClick={ () => {
                        logEvent(analytics, 'buy_paper_version', {"title": comics.name.localized('en')})
                    }}>
                        {t("buy_paper_version")}
        </Button>
        {(currentUser === null || currentUser === undefined) && <Button sx={{width: "100%"}} onClick={() => {updateMode("login")}}>
            {t("login_in_system")}
                </Button>}
        { props.onClose && 
        <Button onClick={props.onClose}>
            {t("i_dont_want")}
        </Button>}
        
    </Box>)
    }

    return (<Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center"}}>
            <NavigationBlock/>
            { mode === "options" && <LockedBlock/>}
            { mode === "login" && <LoginContrainer info={t('register_to_buy')} darkMode={props.darkMode}/>}
            { mode === "buy" && <ConfirmBuyComics comics={comics} updateMode={updateMode} darkMode={props.darkMode}/>}
            { mode === "bought" && <BuySuccessComics comics={comics} darkMode={props.darkMode} onReadComics={onReadComics} onClose={props.onClose}/>}
            { mode === "loading" && <Box sx={{width: "200px", height: "200px"}}>
                <Loader/>
            </Box>}
    </Box>);
}
 
export default ComicsLocked;