export const COLORS = {
    gray: "#262626",
    lightGray: "#716F6E",
    purple: "#654279",
    green: "#3E8425",
    yellow: "#C78321",
    blue: "#0278E1",
    blueLigther: "#3498DB",
    red: "#AF3B3B",
    redLighter: "#F1948A",
    skeleton: 'rgba(255, 255, 255, 0.1)',
    modal_dim: 'rgba(0, 0, 0, 0.8)',
    button_hover: "#C78321"
}

export const FONTS_FAMILY = {
    normal: "Nunito",
    header: "Oswald"
}