
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFile, faGlobe, faXmark, faCartShopping, faArrowsUpDown, faCircleQuestion, faChevronLeft, faEye, faLock, faArrowsLeftRightToLine, faFolderOpen, faFolder, faPlus, faMinus, faForward, faAngleLeft, faGear, faAngleRight, faExpand, faDownload, faPlay, faCloudArrowDown, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faPatreon, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { faMusic } from '@fortawesome/free-solid-svg-icons';
import Reader from './reader/Reader';
import MainLayoutRoutes from './MainLayoutRoutes';
import { COLORS } from './values/values';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import ScrollToTop from './tools/ScrollToTop';
import { CookiesProvider } from 'react-cookie';

library.add(faFile, faPlus, faCartShopping, faCircleQuestion, faEye, faArrowsUpDown, faMusic, faChevronLeft, faLock, faArrowsLeftRightToLine, faFolder, faFolderOpen, faMinus, faFacebook, faPatreon, faAngleLeft, faGear, faAngleRight, faPlay, faExpand, faCloudArrowDown, faForward, faInstagram, faEnvelope, faGlobe, faXmark, faDownload, faTelegram)

function App() {
  let theme = createTheme({
    typography: {
      fontFamily: [
        'Nunito',
        'Caveat'
      ].join(','),
    },});

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <Router> 
            <ScrollToTop/>
            <Box sx={{backgroundColor: COLORS.gray}}>
              <Routes>
                <Route path="/reader/:id/:number" element={<Reader/>} />
                <Route path="*" element={<MainLayoutRoutes />} />
              </Routes>
            </Box>
          </Router>
      </ThemeProvider>
    </CookiesProvider>
    
  );
}

export default App;
