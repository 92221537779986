import { Accordion, AccordionSummary, Box, Button, TextField, Typography } from "@mui/material";
import { auth } from '../utils/firebase';
import { useTranslation } from 'react-i18next'
import { COLORS } from "../values/values";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { updateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import Snackbar from '@mui/material/Snackbar';

const EditAccount = (props) => {
    const { t } = useTranslation()
    const [changePassword, setChangePassword] = useState(false)
    const [editName, setEditName] = useState(false)
    const [newCurrentName, setNewCurrentName] = useState(auth.currentUser.displayName)
    const [displayName, setDisplayName] = useState(auth.currentUser.displayName)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [showChangeSuccess, setShowChangeSuccess] = useState("")
    
    const handleTextFieldChange = (e) => {
      setNewCurrentName(e.target.value)
    }

    const handleOldPasswordChange = (e) => {
      setOldPassword(e.target.value)
    }

    const handleNewPasswordChange = (e) => {
      setNewPassword(e.target.value)
    }

    const handleConfirmPasswordChange = (e) => {
      setConfirmNewPassword(e.target.value)
    }

    const onChangePassword = () => {
      const user = auth.currentUser;

      var credential = EmailAuthProvider.credential(
        auth.currentUser.email, // references the user's email address
        oldPassword
      );
      reauthenticateWithCredential(user, credential).then(() => {
        updatePassword(user, newPassword).then(() => {
          // Update successful.
          handleChange()
          setShowChangeSuccess(t('password_success_change'))
        }).catch((error) => {
          setShowChangeSuccess(t('invalid_error'))
        });
      }).catch((error) => {
        setShowChangeSuccess(t('old_password_wrong'))
      });
    }

    const handleChange =
        () => {
            let close = !changePassword
            setChangePassword(!changePassword)
            if (close) {
                setOldPassword("")
                setNewPassword("")
                setConfirmNewPassword("")
            }
    };

    const startEditMode = () => {
      setEditName(true)
    }

    const closeEditMode = () => {
      setNewCurrentName(auth.currentUser.displayName)
      setEditName(false)
    }

    const saveNewName = () => {
      setEditName(false)
      updateProfile(auth.currentUser, {
        displayName: newCurrentName
      }).then(() => {
        setDisplayName(newCurrentName)
      }).catch((error) => {
        setNewCurrentName(auth.currentUser.displayName)
        setDisplayName(auth.currentUser.displayName)
      });
    }

    return ( <Box width={"80vw"} maxWidth={368}>
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{display: "flex", flexDirection: "row", alignContent: "center", justifyContent: 'space-between', paddingBottom: 2}}>
              <Typography sx={{fontSize: 20, fontWeight: "bold"}}>{t('edit_profile')}:</Typography>
              <Button sx={{ color: "black", minWidth: "44px", fontSize: 18}} onClick={props.onClose}>
                  <FontAwesomeIcon icon={["fas", "xmark"]} />
              </Button>
          </Box>

            { editName ? <Box sx={{display: "flex", alignItems: "center"}}>
              <TextField
                    name="nickname"
                    id="nickname"
                    defaultValue={newCurrentName}
                    label={t('nickname')}
                    onChange={handleTextFieldChange}
                  />
              <Button
                  disabled={newCurrentName.length === 0} 
                  sx={{marginLeft: 2, paddingLeft: 1, paddingRight: 1, bgcolor: COLORS.blue, color: "white",
                  '&:hover': {
                    backgroundColor: COLORS.button_hover,
                    color: "white"}}} onClick={saveNewName}>
                {t('save')}
              </Button>
              <Button sx={{marginLeft: 1}} onClick={closeEditMode}>
                {t('cancel')}
              </Button>
            </Box> : <Box sx={{display: "flex", justifyContent: 'space-between', alignItems: "center"}}>
                <Box sx={{paddingRight: 3}}>
                  <Typography sx={{fontWeight: "bold"}}>{displayName ? displayName : ""}</Typography>
                </Box>
                <Button onClick={startEditMode}>
                  {t('change_nickname')}
                </Button>
            </Box> }
            
            <Accordion  elevation={0} sx={{marginTop: 2, marginBottom: 2}} expanded={changePassword} onChange={handleChange} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              > 
                <Typography>{t('change_password')}</Typography>
              </AccordionSummary>
              <TextField
                  margin="normal"
                  fullWidth
                  label={t('old_password')}
                  type="password"
                  onChange={handleOldPasswordChange}
                />
              <TextField
                    margin="normal"
                    fullWidth
                    label={t('new_password')}
                    type="password"
                    onChange={handleNewPasswordChange}
                  />
              <TextField
                    margin="normal"
                    fullWidth
                    label={t('confirm_new_password')}
                    type="password"
                    onChange={handleConfirmPasswordChange}
                  />
              <Box sx={{paddingTop: 2, paddingBottom: 2, display: "flex", justifyContent: "flex-end"}}>
                <Button
                  disabled={(oldPassword.length === 0 || confirmNewPassword === 0) && confirmNewPassword === newPassword}
                  onClick={onChangePassword}
                  sx={{bgcolor: COLORS.blue, color: "white",
                  '&:hover': {
                    backgroundColor: COLORS.button_hover,
                    color: "white"}}}
                >
                {t('change_password')}
                </Button>
              </Box>
            </Accordion>
            <Snackbar
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                open={showChangeSuccess.length > 0}
                autoHideDuration={3000}
                onClose={() => { setShowChangeSuccess("") }}
                message={showChangeSuccess}
              />
        </Box>
    </Box> );
}
 
export default EditAccount;